/*eslint no-undef: "off"*/

module.exports = {
  campuses: [
    'Online',
    'Sydney',
    'Melbourne',
    'Brisbane',
    'New York',
    'London',
    'Manchester',
  ],
  campusesShort: ['SYD', 'MEL', 'BNE', 'NY', 'LDN', 'MCR'],
  courseTypes: ['Part Time', 'Full Time'],
  courseTypesShort: ['PT', 'FT'],
}
