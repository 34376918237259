import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { withAuthorization } from '../Session'
import { defaultProps, Box } from 'grommet'
import Header from './Header'

import ShilumniIntakesGenerator from './shilumni-intakes-generator'

class Tools extends Component {
  state = {}

  componentDidMount() {
    // this.props.firebase.intakes().then(snapshot => {
    //   this.setState({
    //     loading: false,
    //     intakes: snapshot,
    //   })
    // })
  }

  render() {
    // const { } = this.state

    console.log('PROPS', this.props)

    const { match, firebase } = this.props
    const { params } = match

    const tools = [
      {
        title: 'Shilumni Intakes Generator',
        url: '/tools/shilumni-intake-generator',
        active: true,
      },
    ]

    let body = tools.map((tool, i) => {
      const link = tool.active ? (
        <Link to={tool.url}>{tool.title}</Link>
      ) : (
        <span>{tool.title}</span>
      )
      const body = <ToolLink>{link}</ToolLink>
      return (
        <Box
          key={`tool-${i}`}
          pad="medium"
          background="white"
          border={{ color: 'brand' }}
          round={{ size: 'small' }}
        >
          {body}
        </Box>
      )
    })

    if (params.tool) {
      switch (params.tool) {
        case 'shilumni-intake-generator':
          body = <ShilumniIntakesGenerator firebase={firebase} />
          break

        default:
          break
      }
    }

    return (
      <Box>
        <Header />
        {body}
      </Box>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Tools)

const ToolLink = styled.div`
  a {
    color: ${defaultProps.theme.global.colors.brand};
  }
`
