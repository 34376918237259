import React from 'react'
import { withAuthorization } from '../Session'
import Recent from './Recent'

const Index = ({ history, location, firebase }) => {
  return <Recent firebase={firebase} history={history} location={location} />
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Index)
