import React from 'react'
import styled from 'styled-components'
import { Filter, Search, Trigger, Add } from 'grommet-icons'
import { Heading, Box } from 'grommet'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button } from 'grommet'

const TeachersHeader = ({ history, slug }) => {
  return (
    <Box
      border={{
        color: 'brand',
        size: 'medium',
        style: 'solid',
        side: 'bottom',
      }}
    >
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{
          top: 'large',
          bottom: 'medium',
        }}
      >
        <Heading
          alignSelf="start"
          margin={{ top: '0', bottom: 'small' }}
          color="dark-2"
          level="1"
        >
          Teachers
        </Heading>
      </Box>
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{
          top: 'xsmall',
          bottom: 'medium',
        }}
        gap="small"
      >
        {/* <Box gap="small" direction="row">
          <NavLink isActive={slug === '/teachers'}>
            <Link to="/students">
              <Trigger />
              <span>Recent</span>
            </Link>
          </NavLink>
          <NavLink isActive={slug === '/teachers/filter'}>
            <Link to="/students/filter">
              <Filter />
              <span>Filter</span>
            </Link>
          </NavLink>
          <NavLink isActive={slug === '/teachers/search'}>
            <Link to="/students/search">
              <Search />
              <span>Search</span>
            </Link>
          </NavLink>
        </Box> */}
        <Box>
          <PageButton
            icon={<Add />}
            label="New Teacher"
            onClick={(e) => history.push(`/teachers/add`)}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default withRouter(TeachersHeader)

const NavLink = styled.div`
  a {
    color: ${(props) =>
      props.isActive ? `#fff` : props.theme.global.colors.black};
    text-decoration: none;
    padding: 13px 18px 13px 16px;
    background: ${(props) =>
      props.isActive
        ? props.theme.global.colors.brand
        : props.theme.global.colors['light-3']};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    span {
      display: inline-block;
      padding-left: 0.5em;
    }
    svg {
      stroke: ${(props) =>
        props.isActive ? `#fff` : props.theme.global.colors.black};
    }
    &:hover {
      background: ${(props) =>
        props.isActive
          ? props.theme.global.colors.brand
          : props.theme.global.colors['light-4']};
    }
  }
`

const PageButton = styled(Button)`
  padding: 13px 18px 13px 16px;
  border-radius: 40px;
`
