import React, { Component } from 'react'
import { withAuthorization } from '../Session';
import CsvParse from '@vtex/react-csv-parse'
import { Heading, Box } from 'grommet';
import Header from './Header';
import AddCsvPreview from './AddCsvPreview';
import _ from 'lodash'

const INITIAL_STATE = {
  data: null,
  studentsCreatedEmails: []
};

class AddCsv extends Component {
  state = { ...INITIAL_STATE }

  onSubmit = (event) => {
    _.forEach(this.state.data, (student) => {

      const roles = {
        admin: false,
        student: true
      };
      student.roles = roles
  
      this.props.firebase
        .newStudent(student)
        .then(doc => {
          const studentsCreatedEmails = this.state.studentsCreatedEmails.concat(student.email);
          this.setState({ studentsCreatedEmails: studentsCreatedEmails })
        })
        .catch(error => {
          console.log('ERROR!', error);
        });
    })

    event.preventDefault()
  }

  handleData = data => {
    this.setState({ data })
  }

  handleError = error => {
    console.log('error', error);
  }

  render() {
    const { data, studentsCreatedEmails } = this.state

    const keys = [
      'fullName',
      'email',
      'filemaker',
    ];
  
    return (
      <Box>
        <Header />

        <Box
					border={{
						color: 'brand',
						size: 'medium',
						style: 'solid',
						side: 'top'
					}}
					justify="start"
					direction="column"
					pad={{
						top: 'medium',
						bottom: 'medium'
					}}
				>
					<Box direction="row" justify="between">
						<Box>
							<Heading alignSelf="start" margin={{ top: 'xsmall', bottom: 'xsmall' }} color="dark-2" level="3">
								Import
							</Heading>
						</Box>
					</Box>
				</Box>
      
        <Box margin={{ top: 'xsmall', bottom: 'xsmall' }}>
          <Box margin={{ top: 'xsmall', bottom: 'small' }}>Must be 3 columns (fullName, email, filemaker)</Box>
          <CsvParse
            keys={keys}
            onDataUploaded={this.handleData}
            onError={this.handleError}
            render={onChange => <input type="file" onChange={onChange} />}
          />
        </Box>

        <AddCsvPreview data={data} onSubmit={this.onSubmit} studentsCreatedEmails={studentsCreatedEmails} />

      </Box>
    )
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(AddCsv);