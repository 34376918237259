import React from 'react'

//import { Add } from 'grommet-icons';
import { Heading, Box } from 'grommet';
import { withRouter } from 'react-router-dom'

const HomeHeader = ({ history }) => {
  return (
    <Box direction="row" justify="between" align="center">
      <Heading alignSelf="start" margin={{ vertical: 'large' }} color="dark-2" level="1">
        Welcome
      </Heading>
    </Box>
  )
}

export default withRouter(HomeHeader);
