import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Heading, Box } from 'grommet'
import { IceCream } from 'grommet-icons'
import Header from './Header'
import { Float } from '../../util/theme'
import EditForm from './EditForm'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const INITIAL_STATE = {
  loading: true,
  saving: false,
  saved: false,
  student: null,
}

class StudentAddBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    const studentId = this.props.match.params.student
    this.props.firebase.student(studentId).then(snapshot => {
      this.setState({
        loading: false,
        student: snapshot,
      })
    })
  }

  render() {
    const { loading, student } = this.state
    const { firebase } = this.props

    let body
    if (loading || !student)
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    else {
      if (student.exists) {
        body = (
          <Box>
            <EditForm firebase={firebase} student={student} />
          </Box>
        )
      } else {
        body = <Box>Student not found :(</Box>
      }
    }

    return (
      <Box>
        <Header />
        <Box
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'top',
          }}
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Edit
              </Heading>
            </Box>
            {/* <Box>
              <Menu
                label="Actions"
                items={[
                  {
                    label: 'View',
                    onClick: e =>
                      this.props.history.push(`/students/${student.id}`),
                  },
                ]}
              />
            </Box> */}
          </Box>
        </Box>

        <Box width="medium">{body}</Box>
      </Box>
    )
  }
}

const condition = authUser => !!authUser

const StudentAdd = compose(
  withAuthorization(condition),
  withRouter
)(StudentAddBase)

export default StudentAdd
