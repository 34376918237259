import moment from 'moment'
import {
  campuses,
  campusesShort,
  courseTypes,
  courseTypesShort,
} from './siteConfig'

const getShortCodeFromCity = campus => {
  const index = campuses.indexOf(campus)
  return index > -1 ? campusesShort[index] : ''
}

const getShortCodeFromCourseType = courseType => {
  const index = courseTypes.indexOf(courseType)
  return index > -1 ? courseTypesShort[index] : ''
}

const filterEndDateEarlierThan = (data, endDate) => {
  if (data.docs.length > 0) {
    return data.docs.filter(el => {
      const intakeEndDateTs = parseInt(el.data().endDate.seconds)
      const endDateTs = parseInt(moment(endDate).format('X'))
      if (intakeEndDateTs < endDateTs) {
        return el
      } else {
        return null
      }
    })
  } else {
    return []
  }
}

export {
  getShortCodeFromCity,
  getShortCodeFromCourseType,
  filterEndDateEarlierThan,
}
