import React, { Component } from 'react';
import { withAuthorization } from '../Session';
import { Heading, Box, Button } from 'grommet';
import Header from './Header';
import AddForm from './AddForm';
import { StatusGood } from 'grommet-icons';
import { Link } from 'react-router-dom';

const INITIAL_STATE = {
	created: false,
	student: null
};

class StudentAdd extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
		this.onCreated = this.onCreated.bind(this);
		this.onReset = this.onReset.bind(this);
	}

	onCreated(doc) {
		this.setState({
			created: true,
			student: doc
		});
	}

	onReset() {
		this.setState({
			created: false
		});
	}

	render() {
		const { created, student } = this.state;

		let body;
		if (created) {
			const viewLink = `/students/${student.id}`
			body = (
				<Box>
					<Box direction="row" gap="xsmall" margin={{ bottom: 'medium' }}>
						<StatusGood /> Done! <Link to={viewLink}>View Student</Link>
					</Box>
					<Box direction="row">
						<Button label="Add another" onClick={this.onReset} />
					</Box>
				</Box>
			);
		} else {
			body = <AddForm onChange={this.onChange} onCreated={this.onCreated} firebase={this.props.firebase} />;
		}

		return (
			<Box>
				<Header />

				<Box
					border={{
						color: 'brand',
						size: 'medium',
						style: 'solid',
						side: 'top'
					}}
					justify="start"
					direction="column"
					pad={{
						top: 'medium',
						bottom: 'medium'
					}}
				>
					<Box direction="row" justify="between">
						<Box>
							<Heading alignSelf="start" margin={{ top: 'xsmall', bottom: 'xsmall' }} color="dark-2" level="3">
								Add
							</Heading>
						</Box>
					</Box>
				</Box>

				<Box width="medium">{body}</Box>

				<Box direction="row" margin={{ top: 'medium', bottom: 'medium' }}>
					Or <Link to="/students/add-csv">import via CSV</Link>
				</Box>

			</Box>
		);
	}
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(StudentAdd);
