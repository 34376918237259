import React, { Component } from 'react'
import { Box, Button, Form, FormField } from 'grommet'
import styled from 'styled-components'

const INITIAL_STATE = {
  fullName: '',
  email: '',
  password: '',
  filemaker: '',
  creating: false,
  error: null,
}

export default class AddForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  async onSubmit(e) {
    const { firebase } = this.props
    const { email, fullName, password } = this.state

    const user = {
      fullName,
      email,
      password,
    }

    const funcData = {
      user,
    }

    const teacherCreateFunc =
      firebase.functions.httpsCallable('dbTeacherCreate')

    this.setState({
      creating: true,
    })

    teacherCreateFunc(funcData)
      .then(({ data: { id } }) => {
        this.props.onCreated(id)
      })
      .then((error) => {
        this.setState({ error })
      })

    e.preventDefault()
  }

  render() {
    const { email, fullName, password, creating, error } = this.state

    return (
      <Wrap isCreating={creating}>
        <Form onSubmit={this.onSubmit}>
          <FormField
            label="Name"
            name="fullName"
            value={fullName}
            onChange={this.onChange}
            placeholder="Full Name"
            required
          />
          <FormField
            label="Email"
            name="email"
            value={email}
            type="email"
            onChange={this.onChange}
            placeholder="email@company.com"
            required
          />
          <FormField
            label="Password"
            name="password"
            value={password}
            type="password"
            onChange={this.onChange}
            placeholder="pass"
            required
          />
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button type="submit" label="Create" primary />
          </Box>
          {error && <p>{error.message}</p>}
        </Form>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  opacity: ${(props) => (props.isCreating ? `0.5` : `1`)};
`
