import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Float } from '../../util/theme'
import styled from 'styled-components'
import { Heading, Box, Button, defaultProps } from 'grommet'
import { Link } from 'react-router-dom'
import { IceCream, Emoji } from 'grommet-icons'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Header from './Header'
// import _ from 'lodash'
import Intakes from './Intakes'

class StudentsBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      student: null,
      deleted: false,
      deleting: false,
      deleteError: null,
    }
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    const studentUid = this.props.match.params.student
    this.props.firebase.student(studentUid).then(snapshot => {
      this.setState({
        loading: false,
        student: snapshot,
      })
    })
  }

  onDelete() {
    if (this.state.deleting) return false

    const r = window.confirm(
      'Are you sure? This will permanently delete the Student as well as their associated Stripe plan.'
    )

    if (r === true) {
      this.setState({
        deleting: true,
      })

      const { firebase } = this.props
      const studentId = this.props.match.params.student

      console.log('studentId', studentId)

      const userDeleteFunc = firebase.functions.httpsCallable('dbUserDelete')
      const funcData = {
        studentId: studentId,
      }

      userDeleteFunc(funcData)
        .then(res => {
          console.log('✔ Account deleted', res)
          this.setState({
            deleting: false,
            deleted: true,
          })
        })
        .catch(error => {
          const { message } = error
          // setErrors([`We had a problem removing the account! ${message}`])
          console.log(`✘ Error ${message}`)
          this.setState({
            deleting: false,
          })
        })

      // const { firebase } = this.props
      // const studentId = this.props.match.params.student

      // let batch = firebase.db.batch()

      // const intakeRef = firebase.db
      //   .collection('intakes')
      //   .where(
      //     `students.${studentId}.addedAt`,
      //     '<',
      //     firebase.firestore.Timestamp.fromDate(new Date())
      //   )

      // intakeRef
      //   .get()
      //   .then(snapshot => {
      //     if (snapshot.docs.length > 0) {
      //       _.forEach(snapshot.docs, function(doc) {
      //         const { students } = doc.data()
      //         if (students) {
      //           if (students[studentId]) {
      //             delete students[studentId]
      //             let intakeRef = firebase.intake(doc.id)
      //             batch.update(intakeRef, { students: students })
      //           }
      //         }
      //       })
      //     }

      //     let studentRef = firebase.student(studentId)
      //     batch.delete(studentRef)

      //     batch
      //       .commit()
      //       .then(() => {
      //         this.setState({
      //           deleting: false,
      //           deleted: true,
      //         })
      //       })
      //       .catch(error => {
      //         this.setState({
      //           deleteError: error,
      //           deleting: false,
      //         })
      //       })
      //   })
      //   .catch(error => {
      //     this.setState({
      //       deleteError: error,
      //       deleting: false,
      //     })
      //   })
    }
  }

  render() {
    const { loading, student, deleting, deleted, deleteError } = this.state
    const { history, firebase } = this.props

    let body
    if (loading)
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    else {
      if (deleted) {
        const viewLink = `/students/`
        body = (
          <Status>
            <Box margin={{ top: 'medium' }} direction="row" gap="small">
              <Emoji color="status-ok" size="medium" /> Deleted successfully
              <Link to={viewLink}>Back to Students</Link>
            </Box>
          </Status>
        )
      } else if (student.exists) {
        let status
        if (deleteError) {
          status = (
            <Status>
              <Box margin={{ top: 'medium' }}>
                {deleteError && <p>{deleteError.message}</p>}
              </Box>
            </Status>
          )
        }

        body = (
          <Box>
            <Wrap isDeleting={deleting}>
              <StudentView
                doc={student}
                history={history}
                onDelete={this.onDelete}
              />
              {status}
              <Intakes student={student} firebase={firebase} />
            </Wrap>
          </Box>
        )
      } else {
        body = <Box>Student not found :(</Box>
      }
    }

    return (
      <Box>
        <Header />
        {body}
      </Box>
    )
  }
}

const StudentView = ({ doc, history, onDelete }) => {
  if (!doc.data()) return null

  const { email, fullName } = doc.data()
  //const studentId = doc.id

  return (
    <Box
      justify="start"
      direction="column"
      pad={{
        bottom: 'xlarge',
      }}
    >
      <Box direction="row" justify="between">
        <Box>
          <Heading
            alignSelf="start"
            margin={{ top: 'xsmall', bottom: 'large' }}
            color="dark-2"
            level="3"
          >
            {fullName}
          </Heading>
        </Box>
        {/* <Box>
          <Menu
            label="Actions"
            items={[
              {
                label: 'Edit',
                onClick: e => history.push(`/students/${studentId}/edit`),
              },
            ]}
          />
        </Box> */}
      </Box>
      <Box>
        <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Email
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{email}</Box>
        <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Full Name
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{fullName}</Box>
        {/* <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Filemaker
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{filemaker}</Box> */}
        <Box direction="row" justify="between" margin={{ top: 'medium' }}>
          <Button label="Delete" onClick={e => onDelete()} />
        </Box>
      </Box>
    </Box>
  )
}

const condition = authUser => !!authUser

const Students = compose(withAuthorization(condition), withRouter)(StudentsBase)

export default Students

const Wrap = styled.div`
  opacity: ${props => (props.isDeleting ? `0.5` : `1`)};
`

const Status = styled.div`
  a {
    color: ${defaultProps.theme.global.colors.brand};
    &:hover {
      color: ${defaultProps.theme.global.colors.black};
    }
  }
`
