import React from 'react'
import { withAuthorization } from '../Session'
import Recent from './Recent'

const Index = ({ firebase }) => {
  return <Recent firebase={firebase} />
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Index)
