import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Form, FormField, Text, defaultProps } from 'grommet'
import _ from 'lodash'
import * as JsSearch from 'js-search'
import { theme } from '../../util/theme'
import styled from 'styled-components'
import { withAuthorization } from '../Session'
import Header from './Header'

// const INITIAL_STATUS = {
//   fetching: false,
//   fetched: false,
//   fetchErrors: null,
//   students: [],
// }

const SEARCH = new JsSearch.Search('email')
SEARCH.addIndex('email')
SEARCH.addIndex('fullName')

const Search = ({ firebase }) => {
  // const [status, setStatus] = useState(INITIAL_STATUS)
  const [terms, setTerms] = useState('')
  //const [termsTyped, setTermsTyped] = useState('')
  const [results, setResults] = useState([])

  useEffect(() => {
    let isCancelled = false
    const fetchStudents = async () => {
      await firebase
        .students()
        .get()
        .then((snapshot) => {
          if (!isCancelled) {
            const data = snapshot.docs
              .filter((studentDoc) => {
                return studentDoc.data().roles.student
              })
              .map((studentDoc) => {
                const studentData = studentDoc.data()
                const student = _.pick(studentData, ['email', 'fullName'])
                student.id = studentDoc.id
                return student
              })

            SEARCH.addDocuments(data)

            // setStatus(prevState => ({
            //   ...prevState,
            //   fetching: false,
            //   fetched: true,
            // }))
          }
        })
    }
    fetchStudents()

    return () => {
      isCancelled = true
    }
  }, [firebase])

  useEffect(() => {
    setResults(SEARCH.search(terms))
  }, [terms])

  const onChange = (e) => {
    e.preventDefault()
    const val = e.target.value
    setTimeout(() => {
      setTerms(val)
    }, 500)
  }

  let resultContent
  if (results.length > 0) {
    resultContent = results.map(function (result, i) {
      const { fullName, email, id } = result

      return (
        <Box
          key={i}
          border="bottom"
          justify="between"
          direction="row"
          pad={{
            vertical: 'medium',
          }}
        >
          <Box>
            <Title>
              <Link to={`/students/${id}`}>
                {fullName} ({email})
              </Link>
            </Title>
          </Box>
          <Box>
            <Cta>
              <Link to={`/students/${id}/edit`}>
                <Text weight="bold" size="medium">
                  Edit
                </Text>
              </Link>
              <Link to={`/students/${id}`}>
                <Text weight="bold" size="medium">
                  View
                </Text>
              </Link>
            </Cta>
          </Box>
        </Box>
      )
    })
  }

  return (
    <Box>
      <Header slug="/students/search" />
      <Box width="medium" pad={{ top: 'medium' }}>
        <Form>
          <FormField
            label=""
            name="search"
            value={results}
            placeholder="Name or Email"
            onChange={onChange}
            required
          />
        </Form>
      </Box>
      <Box pad={{ top: 'medium' }}>{resultContent}</Box>
    </Box>
  )
}

const condition = (authUser) => !!authUser
export default withAuthorization(condition)(Search)

const Cta = styled.div`
  a {
    text-decoration: none;
    color: ${defaultProps.theme.global.colors.brand};
    margin-left: 1em;
    &:hover {
      color: ${defaultProps.theme.global.colors.black};
    }
  }
`

const Title = styled.div`
  a {
    text-decoration: none;
    color: ${theme.global.colors.black};
    &:hover {
      color: ${theme.global.colors.black};
    }
  }
`
