import React from 'react'
import { Box, Select } from 'grommet'

const CAMPUSES = [
  'All',
  'Online',
  'Sydney',
  'Melbourne',
  'Brisbane',
  'New York',
  'London',
  'Manchester',
]
const COURSE_TYPES = ['All', 'Part Time', 'Full Time', 'Digital/Masterclass']

const ListFilter = ({ initialValues, defaultValues, history }) => {
  let values
  if (!initialValues) {
    values = defaultValues
  } else {
    values = {
      campus: initialValues.campus || defaultValues.campus,
      year: initialValues.year || defaultValues.year,
      courseType: initialValues.courseType || defaultValues.courseType,
    }
  }

  const handleChange = (key, value) => {
    const params = new URLSearchParams(window.location.search)
    params.set(key, value)
    history.push({
      pathname: window.location.pathname,
      search: `?${params}`,
    })
  }

  const { campus, year, courseType } = values

  const startYear = 2006
  const endYear = new Date().getFullYear() + 2
  const yearOptions = ['All', '1997-2005']
  for (let index = startYear; index < endYear; index++) {
    yearOptions.push(index.toString())
  }

  // if (CAMPUSES[0] !== values.campus) {
  //   CAMPUSES.unshift(values.campus)
  // }

  // if (yearOptions[0] !== values.year) {
  //   yearOptions.unshift(values.year)
  // }

  // if (COURSE_TYPES[0] !== values.courseType) {
  //   COURSE_TYPES.unshift(values.courseType)
  // }

  console.log('CAMPUSES:', CAMPUSES)

  return (
    <Box
      border="bottom"
      justify="start"
      direction="row"
      pad={{
        vertical: 'medium',
      }}
      gap="small"
    >
      <Box width="small">
        <Select
          options={CAMPUSES}
          value={campus}
          onChange={({ option }) => handleChange('campus', option)}
        />
      </Box>
      <Box width="small">
        <Select
          options={COURSE_TYPES}
          value={courseType}
          onChange={({ option }) => handleChange('courseType', option)}
        />
      </Box>
      <Box width="small">
        <Select
          options={yearOptions}
          value={year.toString()}
          onChange={({ option }) => handleChange('year', option)}
        />
      </Box>
    </Box>
  )
}

export default ListFilter
