import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Heading, Box, Button } from 'grommet'
import { Link } from 'react-router-dom'
import Header from './Header'
import Form from './Form'
import { StatusGood } from 'grommet-icons'

const INITIAL_STATE = {
  created: false,
  error: null,
  intake: null,
}

class IntakeAdd extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
    this.onReset = this.onReset.bind(this)
  }

  onSubmit(data) {
    this.props.firebase
      .intakeCreate(data)
      .then(doc => {
        this.setState({ created: true, intake: doc })
      })
      .catch(error => {
        this.setState({ error: error })
      })
  }

  onReset() {
    this.setState({
      created: false,
    })
  }

  render() {
    const { firebase } = this.props
    const { created, intake, error } = this.state

    let body
    if (created && intake) {
      const viewLink = `/intakes/${intake.id}`
      body = (
        <Box>
          <Box direction="row" gap="xsmall" margin={{ bottom: 'medium' }}>
            <StatusGood /> Done! <Link to={viewLink}>View Intake</Link>
          </Box>
          <Box direction="row">
            <Button label="Add another" onClick={this.onReset} />
          </Box>
        </Box>
      )
    } else {
      body = (
        <Form
          onSubmit={this.onSubmit}
          error={error}
          submitLabel="Create"
          firebase={firebase}
        />
      )
    }

    return (
      <Box>
        <Header />
        <Box
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Add
              </Heading>
            </Box>
          </Box>
        </Box>

        <Box width="medium">{body}</Box>
      </Box>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(IntakeAdd)
