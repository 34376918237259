import React, { Component } from 'react'
import _ from 'lodash'
import StudentListItem from './StudentListItem'
import { Heading, Box } from 'grommet'
import { IceCream } from 'grommet-icons'
import { Float } from '../../util/theme'

export default class StudentList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      total: 0,
      totalLoaded: 0,
      loading: true,
      removedIds: [],
      students: [],
      intake: null,
    }

    this.onRemove = this.onRemove.bind(this)
  }

  componentDidMount() {
    this.fetchStudents()
  }

  fetchStudents = async () => {
    const intake = await this.props.firebase.intake(this.props.intake.id)

    const uids = _.keys(intake.data().students)

    this.setState({
      total: uids.length,
      loading: true,
      students: [],
      totalLoaded: 0,
      intake: intake,
    })

    if (uids.length === 0) {
      this.setState({
        total: 0,
        totalLoaded: 0,
        loading: false,
      })
    }

    const getStudent = (uid) => {
      this.props.firebase.student(uid).then((snapshot) => {
        if (snapshot.exists) {
          this.setState((state) => {
            const students = state.students.concat(snapshot)
            const totalLoaded = state.totalLoaded + 1
            const loading = totalLoaded < uids.length
            return {
              students,
              totalLoaded,
              loading,
            }
          })
        } else {
          this.setState((state) => {
            const newTotal = state.total - 1
            const totalLoaded = state.totalLoaded + 1
            const loading = totalLoaded < uids.length
            return {
              total: newTotal,
              totalLoaded: totalLoaded,
              loading: loading,
            }
          })
        }
      })
    }
    _.map(uids, getStudent)
  }

  onRemove(e, studentId) {
    const r = window.confirm(
      'Are you sure? This action will remove the user (student) account as well.'
    )

    if (r === true) {
      const { intake } = this.state
      const { firebase } = this.props
      const intakeId = intake.id

      firebase.intake(intakeId).then((snapshot) => {
        let students = snapshot.data().students

        console.log('1. in..')

        if (students[studentId]) {
          delete students[studentId]
          firebase
            .intakeUpdate(intakeId, { students: students })
            .then(() => {
              console.log('2. in..')

              // delete student
              firebase
                .student(studentId)
                .then((doc) => {
                  console.log('Student deleted')
                })
                .catch((error) => {
                  console.log('Student delete error', error)
                })

              console.log('3. in..')

              // update removedIds
              firebase
                .intake(intakeId)
                .then((doc) => {
                  const { removedIds } = this.state
                  const newRemovedIds = removedIds.slice(0)
                  newRemovedIds.push(studentId)

                  console.log('removedIds', removedIds)

                  this.setState({
                    intake: doc,
                    removedIds: newRemovedIds,
                  })
                })
                .catch((error) => {
                  console.log('ERROR', error)
                })
            })
            .catch(() => {})
        }
      })
    }
  }

  render() {
    const { total, students, loading, intake, removedIds } = this.state
    const { firebase } = this.props

    //console.log('>>>>', total)

    let body
    if (total === 0 && !loading) {
      body = <p>No students enrolled yet.</p>
    } else if (loading) {
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    } else {
      console.log('students', students)
      body = students.map((student, i) => {
        let isRemoved
        if (student && student.exists) {
          isRemoved = removedIds.indexOf(student.id) > -1
        }

        console.log('isRemoved', isRemoved)
        console.log('>>>>', student.data())

        return student.exists ? (
          <StudentListItem
            key={i}
            student={student}
            firebase={firebase}
            intake={intake}
            onRemove={this.onRemove}
            isRemoved={isRemoved}
          />
        ) : null
      })
    }

    return (
      <Box
        border={{
          color: 'black',
          size: 'small',
          style: 'solid',
          side: 'top',
        }}
      >
        <Box direction="row" justify="between" align="center">
          <Heading
            alignSelf="start"
            margin={{ top: 'medium', bottom: 'medium' }}
            color="dark-2"
            level="3"
          >
            Students
          </Heading>
        </Box>
        {body}
      </Box>
    )
  }
}
