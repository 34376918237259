export const LANDING = '/'
export const SIGN_IN = '/sign-in'
export const HOME = '/home'

export const INTAKES = '/intakes'
export const INTAKES_FILTER = '/intakes/filter'
export const INTAKE_ADD = '/intakes/add'
export const INTAKE_VIEW = '/intakes/:intake'
export const INTAKE_EDIT = '/intakes/:intake/edit'
export const INTAKE_ADD_STUDENTS = '/intakes/:intake/add-students'
export const INTAKE_ADD_STUDENTS_CSV = '/intakes/:intake/add-students-csv'
export const INTAKE_ADD_STUDENTS_MANUAL = '/intakes/:intake/add-students-manual'

export const STUDENTS = '/students'
export const STUDENTS_FILTER = '/students/filter'
export const STUDENTS_SEARCH = '/students/search'
export const STUDENT_ADD = '/students/add'
export const STUDENT_ADD_CSV = '/students/add-csv'
export const STUDENT_VIEW = '/students/:student'
export const STUDENT_EDIT = '/students/:student/edit'

export const TEACHERS = '/teachers'
export const TEACHERS_ADD = '/teachers/add'
export const TEACHERS_VIEW = '/teachers/:teacher'

export const TOOLS = '/tools'
export const TOOL_VIEW = '/tools/:tool'
