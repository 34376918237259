import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Heading, Box, Menu } from 'grommet'
import { IceCream } from 'grommet-icons'
import Header from './Header'
import { Float } from '../../util/theme'
import Form from './Form'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const INITIAL_STATE = {
  loading: true,
  submitted: false,
  error: null,
  intake: null,
}

class IntakeEditBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    const intakeId = this.props.match.params.intake
    this.props.firebase.intake(intakeId).then((snapshot) => {
      this.setState({
        loading: false,
        intake: snapshot,
      })
    })
  }

  onSubmit = (data) => {
    console.log('Update intake???', data)
    // console.log('>>>>', this.props.firebase.auth)

    this.props.firebase
      .intakeUpdate(this.state.intake.id, data)
      .then(() => {
        console.log('all good!')
        this.setState({ submitted: true })
      })
      .catch((error) => {
        console.log('error >>>', error)
        this.setState({ error: error })
      })
  }

  render() {
    const { firebase } = this.props
    const { loading, intake, error, submitted } = this.state

    let body, menu
    if (loading || !intake)
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    else {
      if (intake.exists) {
        body = (
          <Form
            onSubmit={this.onSubmit}
            submitted={submitted}
            submitLabel="Update"
            firebase={firebase}
            data={intake.data()}
            error={error}
          />
        )
        menu = (
          <Box>
            <Menu
              label="Actions"
              items={[
                {
                  label: 'View',
                  onClick: (e) =>
                    this.props.history.push(`/intakes/${intake.id}`),
                },
              ]}
            />
          </Box>
        )
      } else {
        body = <Box>Intake not found :(</Box>
      }
    }

    return (
      <Box>
        <Header />
        <Box
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Edit
              </Heading>
            </Box>
            {menu}
          </Box>
        </Box>

        <Box width="medium">{body}</Box>
      </Box>
    )
  }
}

const condition = (authUser) => !!authUser

const IntakeAdd = compose(
  withAuthorization(condition),
  withRouter
)(IntakeEditBase)

export default IntakeAdd
