import React, { Component } from 'react'
import { Box, Button, Form, FormField } from 'grommet'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { defaultProps } from 'grommet'
import { Emoji } from 'grommet-icons'

export default class EditForm extends Component {
  constructor(props) {
    super(props)

    const { fullName, email, filemaker } = this.props.student.data()
    this.state = {
      fullName: fullName,
      email: email,
      filemaker: filemaker,
      saved: false,
      saving: false,
      error: null,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit(e) {
    const { firebase } = this.props
    const studentId = this.props.student.id
    const { email, fullName, filemaker } = this.state

    this.setState({ saving: true, saved: false })

    const userRef = firebase.db.collection('users').doc(studentId)
    userRef
      .update({
        email,
        fullName,
        filemaker,
      })
      .then(() => {
        this.setState({ saving: false, saved: true })
      })
      .catch(error => {
        this.setState({
          saving: true,
          error: error,
        })
      })

    e.preventDefault()
  }

  render() {
    const { email, fullName, saved, saving, error } = this.state

    let status
    if (saved) {
      const viewLink = `/students/${this.props.student.id}`
      status = (
        <Status>
          <Box margin={{ top: 'medium' }} direction="row" gap="small">
            <Emoji color="status-ok" size="medium" /> Saved successfully
            <Link to={viewLink}>View</Link>
          </Box>
        </Status>
      )
    } else if (error) {
      status = (
        <Status>
          <Box margin={{ top: 'medium' }}>
            {error && <p>{error.message}</p>}
          </Box>
        </Status>
      )
    }

    return (
      <Wrap isSaving={saving}>
        <Form onSubmit={this.onSubmit}>
          <FormField
            label="Name"
            name="fullName"
            value={fullName}
            onChange={this.onChange}
            placeholder="Full Name"
            required
          />
          <FormField
            label="Email"
            name="email"
            value={email}
            type="email"
            onChange={this.onChange}
            placeholder="email@company.com"
            required
          />
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button type="submit" label="Save" primary />
          </Box>
          {status}
        </Form>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  opacity: ${props => (props.isSaving ? `0.5` : `1`)};
`

const Status = styled.div`
  a {
    color: ${defaultProps.theme.global.colors.brand};
    &:hover {
      color: ${defaultProps.theme.global.colors.black};
    }
  }
`
