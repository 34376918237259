import React from 'react'
import ListItem from './ListItem'

const IntakesList = ({ docs }) => {
  if (!docs) return null
  if (docs.length > 0) {
    return docs.map((doc, i) => <ListItem key={i} doc={doc} />)
  } else {
    return <p>No intakes found :(</p>
  }
}

export default IntakesList
