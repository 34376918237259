import React, { Component } from 'react'
import CsvParse from '@vtex/react-csv-parse'
import { Heading, Box, Button } from 'grommet'
import AddCsvPreview from './AddCsvPreview'
import styled from 'styled-components'

const INITIAL_STATE = {
  data: null,
  studentsCreatedEmails: [],
  adding: false,
  added: false,
  addError: null,
}

const csvKeys = ['fullName', 'email', 'filemaker']

class AddCsv extends Component {
  state = { ...INITIAL_STATE }

  onSubmit = event => {
    const { intake, firebase } = this.props
    const intakeId = intake.id

    this.setState({
      adding: true,
      added: false,
      addError: null,
    })

    const addStudents = async students => {
      for (let i = 0; i < students.length; i++) {
        const student = students[i]

        const roles = {
          admin: false,
          student: true,
        }
        student.roles = roles

        const now = firebase.firestore.Timestamp.fromDate(new Date())

        console.log('createStudent CREATE', student.email)

        const createStudent = await firebase.newStudent(student)

        console.log('createStudent CREATED', createStudent.id)

        const intake = await firebase.intake(intakeId)

        let intakeStudents = intake.data().students

        if (intakeStudents) {
          if (!intakeStudents[createStudent.id]) {
            intakeStudents[createStudent.id] = {
              inviteDate: '',
              inviteAcceptedDate: '',
              addedAt: now,
            }
          }
        } else {
          intakeStudents = {}
          intakeStudents[createStudent.id] = {
            inviteDate: '',
            inviteAcceptedDate: '',
            addedAt: now,
          }
        }

        console.log('intakeUpdate UPDATE')

        await firebase.intakeUpdate(intake.id, {
          students: intakeStudents,
        })

        console.log('intakeUpdate UPDATED')

        const studentsCreatedEmails = this.state.studentsCreatedEmails.concat(
          student.email
        )
        this.setState({ studentsCreatedEmails: studentsCreatedEmails })

        if (studentsCreatedEmails.length === students.length) {
          setTimeout(() => {
            this.setState({
              adding: false,
              added: true,
              addError: null,
              data: null,
              studentsCreatedEmails: [],
            })
          }, 500)
        }
      }
    }

    addStudents(this.state.data)
    event.preventDefault()
  }

  handleData = data => {
    this.setState({ data })
  }

  handleError = error => {
    console.log('error', error)
  }

  render() {
    const { data, studentsCreatedEmails, adding, added } = this.state

    let body
    if (added) {
      body = (
        <Status>
          <p>All done!</p>
          <Button
            label="Import More"
            onClick={e =>
              this.setState({
                added: false,
              })
            }
          />
        </Status>
      )
    } else {
      body = (
        <Status isAdding={adding}>
          <Box margin={{ top: 'xsmall', bottom: 'small' }}>
            Must be 3 columns (fullName, email, filemaker)
          </Box>
          <CsvParse
            keys={csvKeys}
            onDataUploaded={this.handleData}
            onError={this.handleError}
            render={onChange => <input type="file" onChange={onChange} />}
          />
        </Status>
      )
    }

    body = <p>Add via CSV will be back soon!</p>

    return (
      <Box>
        <Box
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'top',
          }}
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Add Students via CSV
              </Heading>
            </Box>
          </Box>
        </Box>

        <Box margin={{ top: 'xsmall', bottom: 'xsmall' }}>{body}</Box>

        {!added ? (
          <AddCsvPreview
            data={data}
            onSubmit={this.onSubmit}
            studentsCreatedEmails={studentsCreatedEmails}
            isAdding={adding}
          />
        ) : null}
      </Box>
    )
  }
}

export default AddCsv

const Status = styled.div`
  opacity: ${props => (props.isAdding === true ? `0.5` : `1`)};
`
