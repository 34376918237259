import React from "react";
import { Heading, Box, Button } from "grommet";
import { CloudUpload, Keyboard } from "grommet-icons";
import { withRouter } from "react-router-dom";

const StudentsIndex = ({ match, history }) => {
  const { intake } = match.params;

  return (
    <Box
      border={{
        color: "black",
        size: "small",
        style: "solid",
        side: "top"
      }}
    >
      <Box direction="row" justify="between" align="center">
        <Heading
          alignSelf="start"
          margin={{ top: "medium", bottom: "medium" }}
          color="dark-2"
          level="3"
        >
          Add Students
        </Heading>
      </Box>
      <Box
        direction="row"
        justify="center"
        align="center"
        pad="xlarge"
        background="light-1"
        gap="medium"
      >
        <Box
          pad="large"
          align="center"
          background="light-3"
          round
          gap="small"
          flex="grow"
        >
          <CloudUpload size="large" />
          <Button
            label="CSV Upload"
            onClick={e => history.push(`/intakes/${intake}/add-students-csv/`)}
          />
        </Box>
        <Box
          pad="large"
          align="center"
          background="light-3"
          round
          gap="small"
          flex="grow"
        >
          <Keyboard size="large" />
          <Button
            label="Manual Entry"
            onClick={e =>
              history.push(`/intakes/${intake}/add-students-manual/`)
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(StudentsIndex);
