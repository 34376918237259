import React from 'react'
import styled from 'styled-components'
import { withFirebase } from '../Firebase'

const SignOutButton = ({ firebase }) => {
  return (
    <Link label="Sign Out" href="#" onClick={firebase.doSignOut}>
      Sign out
    </Link>
  )
}

export default withFirebase(SignOutButton)

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.global.colors.black};
  &:hover {
    color: ${props => props.theme.global.colors.brand};
  }
`
