import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Float } from '../../util/theme'
import styled from 'styled-components'
import { Heading, Box, Button, defaultProps } from 'grommet'
import { Link } from 'react-router-dom'
import { IceCream, Emoji } from 'grommet-icons'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Header from './Header'
// import _ from 'lodash'

class TeachersBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      teacher: null,
      deleted: false,
      deleting: false,
      deleteError: null,
    }
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    const teacherUid = this.props.match.params.teacher
    this.props.firebase.teacher(teacherUid).then((snapshot) => {
      this.setState({
        loading: false,
        teacher: snapshot,
      })
    })
  }

  onDelete() {
    if (this.state.deleting) return false

    const r = window.confirm(
      'Are you sure? This will permanently delete the Teacher.'
    )

    if (r === true) {
      this.setState({
        deleting: true,
      })

      const { firebase } = this.props
      const teacherId = this.props.match.params.teacher

      console.log('teacherId', teacherId)

      const userDeleteFunc = firebase.functions.httpsCallable('dbTeacherDelete')
      const funcData = {
        teacherId: teacherId,
      }

      userDeleteFunc(funcData)
        .then((res) => {
          console.log('✔ Account deleted', res)
          this.setState({
            deleting: false,
            deleted: true,
          })
        })
        .catch((error) => {
          const { message } = error
          // setErrors([`We had a problem removing the account! ${message}`])
          console.log(`✘ Error ${message}`)
          this.setState({
            deleting: false,
          })
        })
    }
  }

  render() {
    const { loading, teacher, deleting, deleted, deleteError } = this.state
    const { history, firebase } = this.props

    let body
    if (loading)
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    else {
      if (deleted) {
        const viewLink = `/teachers/`
        body = (
          <Status>
            <Box margin={{ top: 'medium' }} direction="row" gap="small">
              <Emoji color="status-ok" size="medium" /> Deleted successfully
              <Link to={viewLink}>Back to Teachers</Link>
            </Box>
          </Status>
        )
      } else if (teacher.exists) {
        let status
        if (deleteError) {
          status = (
            <Status>
              <Box margin={{ top: 'medium' }}>
                {deleteError && <p>{deleteError.message}</p>}
              </Box>
            </Status>
          )
        }

        body = (
          <Box>
            <Wrap isDeleting={deleting}>
              <TeacherView
                doc={teacher}
                history={history}
                onDelete={this.onDelete}
              />
              {status}
            </Wrap>
          </Box>
        )
      } else {
        body = <Box>Teacher not found :(</Box>
      }
    }

    return (
      <Box>
        <Header />
        {body}
      </Box>
    )
  }
}

const TeacherView = ({ doc, history, onDelete }) => {
  if (!doc.data()) return null

  const { email, fullName } = doc.data()

  return (
    <Box
      justify="start"
      direction="column"
      pad={{
        bottom: 'xlarge',
      }}
    >
      <Box direction="row" justify="between">
        <Box>
          <Heading
            alignSelf="start"
            margin={{ top: 'xsmall', bottom: 'large' }}
            color="dark-2"
            level="3"
          >
            {fullName}
          </Heading>
        </Box>
      </Box>
      <Box>
        <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Email
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{email}</Box>
        <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Full Name
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{fullName}</Box>
        {/* <Heading
          alignSelf="start"
          margin={{ top: 'xsmall', bottom: 'xsmall' }}
          color="dark-2"
          level="4"
        >
          Filemaker
        </Heading>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>{filemaker}</Box> */}
        <Box direction="row" justify="between" margin={{ top: 'medium' }}>
          <Button label="Delete" onClick={(e) => onDelete()} />
        </Box>
      </Box>
    </Box>
  )
}

const condition = (authUser) => !!authUser

const Teachers = compose(withAuthorization(condition), withRouter)(TeachersBase)

export default Teachers

const Wrap = styled.div`
  opacity: ${(props) => (props.isDeleting ? `0.5` : `1`)};
`

const Status = styled.div`
  a {
    color: ${defaultProps.theme.global.colors.brand};
    &:hover {
      color: ${defaultProps.theme.global.colors.black};
    }
  }
`
