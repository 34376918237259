import React, { useState, useEffect, useRef } from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  fetchErrors: null,
  subscription: `loading plan`,
}

export default ({ doc, firebase, index }) => {
  const { fullName, email, stripe } = doc.data()

  const [status, setStatus] = useState(INITIAL_STATE)
  const { subscription } = status
  const timerRef = useRef(0)

  useEffect(() => {
    const fetchSubscription = async () => {
      setStatus(prevState => ({
        ...prevState,
        fetching: true,
      }))
      const retrievePlanFunc = firebase.functions.httpsCallable(
        'stripeGetSubscription'
      )

      const sub = await retrievePlanFunc({ stripe })

      let subscriptionText = 'n/a'
      if (sub.data && sub.data.stripe && sub.data.stripe.plan) {
        const { plan } = sub.data.stripe
        const { amount, currency, nickname } = plan
        let duration = 'p/month'
        if (nickname === 'Yearly') {
          duration = 'p/year'
        }
        const symbol = currency === 'gbp' ? '£' : '$'
        subscriptionText = `${symbol}${amount /
          100}${currency.toUpperCase()} ${duration}`
      }

      setStatus(prevState => ({
        ...prevState,
        fetched: true,
        fetching: false,
        subscription: subscriptionText,
      }))
    }

    // Offset calls to server :)
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      fetchSubscription()
    }, 200 * index)

    return () => {
      clearTimeout(timerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      border="bottom"
      justify="between"
      direction="row"
      pad={{
        vertical: 'medium',
      }}
    >
      <Box>
        <Title>
          <Link to={`/students/${doc.id}`}>
            {fullName} ({email})
          </Link>
        </Title>
      </Box>
      <Box>
        <Status>{subscription}</Status>
      </Box>
    </Box>
  )
}

const Status = styled.span`
  color: ${props => props.theme.global.colors.placeholder};
`

const Title = styled.div`
  a {
    text-decoration: none;
    color: ${props => props.theme.global.colors.black};
    &:hover {
      color: ${props => props.theme.global.colors.black};
    }
  }
`
