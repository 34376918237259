import React from 'react'
import { withAuthorization } from '../Session';
import Header from './Header'
import { Box } from 'grommet';

const HomePage = () => {
  return (
    <Box>
      <Header />
    </Box>
  )
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);
