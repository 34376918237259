import React from 'react'
import styled from 'styled-components'

const FormError = ({ children }) => {
  return <El>{children}</El>
}

export default FormError

const El = styled.div`
  margin-top: 0.1em;
  margin-bottom: 0.6em;
  color: ${props => props.theme.global.colors['status-error']};
  font-size: 90%;
`
