import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import queryString from 'query-string'
import { withAuthorization } from '../Session'
import { filterEndDateEarlierThan } from '../../util/helpers'
import Header from './Header'
import ListItem from './ListItem'
import ListFilter from './ListFilter'
import Loader from '../Global/Loader'

const CURR_DATE = new Date()
const CURR_YEAR = CURR_DATE.getFullYear()

const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  fetchErrors: null,
  intakes: [],
}

const INITIAL_VALUES = {
  campus: 'New York',
  year: CURR_YEAR.toString(),
  courseType: 'Full Time',
}

const Filter = ({ history, location, firebase }) => {
  const [status, setStatus] = useState(INITIAL_STATE)
  const [values, setValues] = useState(null)

  const { fetching, fetched, intakes } = status

  useEffect(() => {
    let isCancelled = false

    setStatus(INITIAL_STATE)
    let values = INITIAL_VALUES
    if (location && location.search) {
      const parsed = queryString.parse(location.search)
      const { campus, year, courseType } = parsed
      values = {
        campus: campus || INITIAL_VALUES.campus,
        year: year || INITIAL_VALUES.year,
        courseType: courseType || INITIAL_VALUES.courseType,
      }
    }
    setValues(values)

    const fetchFiltered = async values => {
      setStatus(prevState => ({
        ...prevState,
        fetching: true,
        intakes: [],
      }))
      const { campus, year, courseType } = values

      let startDate
      if (year === 'All') {
        startDate = new Date(`${1996 - 1}-12-31`)
      } else if (year === '1997-2005') {
        startDate = new Date(`${1997 - 1}-12-31`)
      } else {
        startDate = new Date(`${parseInt(year) - 1}-12-31`)
      }
      const startTs = firebase.firestore.Timestamp.fromDate(startDate)

      const endMonths = courseType === 'Full Time' ? `03-01` : `09-01`
      const endYear =
        year === 'All' ? CURR_YEAR + 1 : year === '1997-2005' ? '2005' : year
      console.log(endYear)

      const endDate = new Date(`${parseInt(endYear) + 1}-${endMonths}`)

      const res = await firebase
        .filteredIntakes(campus, startTs, courseType)
        .catch(err => {
          console.log('✘ Error:', err)
        })

      if (!isCancelled) {
        if (res.docs.length > 0) {
          const filteredIntakes = filterEndDateEarlierThan(res, endDate)
          setStatus(prevState => ({
            ...prevState,
            fetching: false,
            fetched: true,
            intakes: filteredIntakes,
          }))
        } else {
          setStatus(prevState => ({
            ...prevState,
            fetching: false,
            fetched: true,
            intakes: [],
          }))
        }
      }
    }

    fetchFiltered(values)

    return () => {
      isCancelled = true
    }
  }, [firebase, location])

  let body
  if (fetching && !fetched) {
    body = <Loader />
  } else if (fetched && intakes.length > 0) {
    body = intakes.map((intake, i) => {
      return <ListItem key={i} doc={intake} />
    })
  } else if (fetched && intakes.length === 0) {
    body = (
      <Box
        border="bottom"
        justify="between"
        direction="row"
        pad={{
          vertical: 'medium',
        }}
      >
        No intakes found.
      </Box>
    )
  }

  return (
    <Box>
      <Header slug="/intakes/filter" />
      <ListFilter
        history={history}
        initialValues={values}
        defaultValues={INITIAL_VALUES}
      />
      {body}
    </Box>
  )
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Filter)

/*
class Intakes extends Component {
  state = {
    loading: true,
    intakes: null,
    values: {
      campus: null,
      year: null,
      courseType: null,
    },
  }

  constructor(props) {
    super(props)
    this.handleDropchange = this.handleDropchange.bind(this)
  }

  componentDidMount() {
    // Get / Set URL
    const params = new URLSearchParams(window.location.search)
    const paramCampus = params.get('campus') || DEFAULT_VALUES.campus
    const paramYear = params.get('year') || DEFAULT_VALUES.year
    const paramCourseType =
      params.get('courseType') || DEFAULT_VALUES.courseType

    params.set('campus', paramCampus)
    params.set('year', paramYear)
    params.set('courseType', paramCourseType)
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`)

    const values = {
      campus: paramCampus,
      year: paramYear,
      courseType: paramCourseType,
    }

    this.setState({ values })
    this.fetchIntakes(values)
  }

  async fetchIntakes(values) {
    this.setState({
      loading: true,
      intakes: [],
    })

    const { firebase } = this.props
    const { campus, year, courseType } = values

    const startDate =
      year === '1997-2005'
        ? new Date(`${1997 - 1}-12-31`)
        : new Date(`${parseInt(year) - 1}-12-31`)
    const startTs = firebase.firestore.Timestamp.fromDate(startDate)

    const endMonths = courseType === 'Full Time' ? `03-01` : `09-01`
    const endYear = year === '1997-2005' ? '2005' : year
    const endDate = new Date(`${parseInt(endYear) + 1}-${endMonths}`)

    const res = await firebase.filteredIntakes(campus, startTs, courseType)

    if (res.docs.length > 0) {
      const filteredIntakes = filterEndDateEarlierThan(res, endDate)
      this.setState({
        loading: false,
        intakes: filteredIntakes,
      })
    } else {
      this.setState({
        loading: false,
        intakes: [],
      })
    }
  }

  handleDropchange(values) {
    if (
      values.campus !== 'Campus' &&
      values.year !== 'Year' &&
      values.courseType !== 'Type'
    ) {
      this.setState({ values })
      this.fetchIntakes(values)
    }
  }

  render() {
    const { loading, intakes, values } = this.state

    let body
    if (loading)
      body = (
        <Float>
          <Java color="dark-2" size="medium" />
        </Float>
      )
    else {
      body = (
        <Box>
          <IntakesList data={intakes} />
        </Box>
      )
    }

    let listFilter
    if (values.campus && values.year && values.courseType) {
      listFilter = (
        <ListFilter
          handleDropchange={this.handleDropchange}
          initialValues={values}
        />
      )
    }

    //{listFilter}

    return (
      <Box>
        <Header slug="/intakes" />
        
        <ListFilter
        history={history}
        initialValues={values}
        defaultValues={INITIAL_VALUES}
      />
        {body}
      </Box>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Intakes)

*/
