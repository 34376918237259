import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Box, Button, defaultProps, Heading } from 'grommet'
import Item from './item'

const ShilumniIntakGenerator = ({ firebase }) => {
  const [isRunning, setIsRunning] = useState(false)
  const [intakes, setIntakes] = useState([])
  const [createdIntakes, setCreatedIntakes] = useState([])
  const [existingIntakes, setExistingIntakes] = useState([])
  const [checkedIntakes, setCheckedIntakes] = useState([])

  const urlObject = new URL(window.location.href)
  const paramBatch = urlObject.searchParams.get('batch')
  const batch = paramBatch ? parseInt(paramBatch) : 1

  useEffect(() => {
    setIsRunning(false)
    setIntakes([])
    setCreatedIntakes([])
    setExistingIntakes([])
    setCheckedIntakes([])
  }, [batch])

  useEffect(() => {
    if (
      createdIntakes.length + 1 + existingIntakes.length ===
      checkedIntakes.length
    ) {
      setIsRunning(false)
    }
  }, [
    createdIntakes,
    createdIntakes.length,
    existingIntakes.length,
    checkedIntakes.length,
  ])

  const onPreview = () => {
    const intakes = buildIntakeList(firebase, batch)
    setIntakes(intakes)
  }

  const onRun = () => {
    setIsRunning(true)
  }

  const handleChecked = code => {
    setCheckedIntakes(prev => [...prev, code])
  }

  const handleExists = code => {
    setExistingIntakes(prev => [...prev, code])
  }

  const handleCreated = code => {
    setCreatedIntakes(prev => [...prev, code])
  }

  console.log(
    '>>>',
    createdIntakes.length,
    existingIntakes.length,
    checkedIntakes.length
  )

  const creatingContent = isRunning ? (
    <Box direction="row" gap="small" margin={{ bottom: 'large' }}>
      Creating...
    </Box>
  ) : (
    ''
  )

  let runButton
  if (
    checkedIntakes.length > 0 &&
    checkedIntakes.length === intakes.length &&
    existingIntakes.length < checkedIntakes.length &&
    !isRunning &&
    createdIntakes.length === 0
  ) {
    runButton = (
      <Box direction="row">
        <Button label="Create Intakes" onClick={onRun} />
      </Box>
    )
  }

  const batches = [
    { name: '1997-2005' },
    { name: '2006-2009' },
    { name: '2010-2014' },
    { name: '2015-2019' },
  ]

  return (
    <div>
      <p>Generator for past (pre 2020) Shilumni sign-ups.</p>
      <Box
        direction="row"
        justify="start"
        margin={{ top: 'large', bottom: 'large' }}
      >
        {batches.map((batchItem, index) => {
          return (
            <Batch key={`key-${index + 1}`} isActive={batch === index + 1}>
              <Link to={`/tools/shilumni-intake-generator?batch=${index + 1}`}>
                {batchItem.name}
              </Link>
            </Batch>
          )
        })}
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Heading margin={{ bottom: 'medium' }} level="3">
          {batches[batch - 1].name}
        </Heading>
        <Box direction="row" gap="small">
          <Button label="Preview Intakes" onClick={onPreview} />
          {runButton}
        </Box>
      </Box>
      {creatingContent}
      <Stats
        intakes={intakes}
        createdIntakes={createdIntakes}
        existingIntakes={existingIntakes}
        checkedIntakes={checkedIntakes}
      />
      <List
        firebase={firebase}
        intakes={intakes}
        handleChecked={handleChecked}
        handleExists={handleExists}
        handleCreated={handleCreated}
        isRunning={isRunning}
      />
    </div>
  )
}

export default ShilumniIntakGenerator

const Stats = ({
  intakes,
  checkedIntakes,
  createdIntakes,
  existingIntakes,
}) => {
  if (intakes.length === 0) return null

  return (
    <Box direction="row" margin={{ top: 'large' }}>
      <Stat>
        Total: <strong>{intakes.length}</strong>, Checked:{' '}
        <strong>{checkedIntakes.length}</strong>, Existing:{' '}
        <strong>{existingIntakes.length}</strong>, Created:{' '}
        <strong>{createdIntakes.length}</strong>
      </Stat>
    </Box>
  )
}

const List = ({
  firebase,
  intakes,
  handleChecked,
  handleExists,
  handleCreated,
  isRunning,
}) => {
  return intakes.map((intake, index) => {
    const key = `intake-${index}`
    return (
      <Item
        firebase={firebase}
        data={intake}
        key={key}
        handleChecked={handleChecked}
        handleExists={handleExists}
        handleCreated={handleCreated}
        isRunning={isRunning}
      />
    )
  })
}

const Stat = styled.div`
  color: ${defaultProps.theme.global.colors.brand};
`

const Batch = styled.div`
  a {
    text-decoration: none;
    padding-bottom: 6px;
    margin: 3px 12px 3px 0px;
    color: ${defaultProps.theme.global.colors.brand};
    border-bottom: 2px solid
      ${props =>
        props.isActive
          ? defaultProps.theme.global.colors['dark-2']
          : defaultProps.theme.global.colors.brand};
    &:hover {
      border-bottom: 2px solid ${defaultProps.theme.global.colors.black};
    }
  }
`

// Generator logic

const allYears = [
  '1997-2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
]

const cities = [
  { name: 'Brisbane', code: 'BNE' },
  { name: 'Sydney', code: 'SYD' },
  { name: 'Melbourne', code: 'MEL' },
  { name: 'London', code: 'LON' },
  { name: 'Manchester', code: 'MAN' },
  { name: 'New York', code: 'NYC' },
]

const studyTypes = [
  {
    name: 'Full Time',
    code: 'FT',
    commence: 'Jan/Feb',
    start: '01-01-',
    end: '30-04-',
  },
  {
    name: 'Full Time',
    code: 'FT',
    commence: 'Apr/Jun',
    start: '01-04-',
    end: '31-07-',
  },
  {
    name: 'Full Time',
    code: 'FT',
    commence: 'Sep',
    start: '01-09-',
    end: '31-12-',
  },
  {
    name: 'Part Time',
    code: 'PT',
    commence: 'Feb',
    start: '01-02-',
    end: '31-08-',
  },
  {
    name: 'Part Time',
    code: 'PT',
    commence: 'Sep',
    start: '01-09-',
    end: '31-03-',
  },
  {
    name: 'Part Time',
    code: 'PT',
    commence: 'Sunday',
    start: '01-09-',
    end: '31-07-',
  },
  {
    name: 'Digital/Masterclass',
    code: 'DM',
    commence: '',
    start: '01-01-',
    end: '31-12-',
  },
]

const buildIntakeList = (firebase, batch) => {
  const genIntakes = []

  let years = []
  if (batch === 1) {
    years = allYears.slice(0, 1)
  } else if (batch === 2) {
    years = allYears.slice(1, 5)
  } else if (batch === 3) {
    years = allYears.slice(5, 10)
  } else if (batch === 4) {
    years = allYears.slice(10, allYears.length)
  }

  for (let x = 0; x < years.length; x++) {
    for (let y = 0; y < cities.length; y++) {
      for (let z = 0; z < studyTypes.length; z++) {
        const year = years[x]
        const cityName = cities[y].name
        const cityCode = cities[y].code
        const studyTypeName = studyTypes[z].name
        const studyTypeCode = studyTypes[z].code
        const studyTypeCommence = studyTypes[z].commence
        const studyTypeCommenceCode = studyTypeCommence
          .toUpperCase()
          .replace('/', '')

        let studyTypeStart = studyTypes[z].start
        let studyTypeEnd = studyTypes[z].end

        if (year === '1997-2005') {
          studyTypeStart = '01-01-'
          studyTypeEnd = '31-12-'
        }

        const startYear = year.substring(0, 4)

        let endYear = year.substring(year.length - 4, year.length)
        if (
          (studyTypeCode === 'PT' &&
            studyTypeCommence === 'Sep' &&
            year !== '1997-2005') ||
          studyTypeCommence === 'Sunday'
        ) {
          endYear = parseInt(endYear) + 1
        }

        if (
          (studyTypeCommence === 'Sunday' && parseInt(year) > 2018) ||
          studyTypeCommence !== 'Sunday'
        ) {
          const commenceDash = studyTypeCommenceCode !== '' ? `-` : ``
          const commenceSpace = studyTypeCommenceCode !== '' ? ` ` : ``

          const startDateMoment = moment(
            `${studyTypeStart}${startYear}`,
            'DD-MM-YYYY'
          ).toDate()
          const startDateTimestamp = firebase.firestore.Timestamp.fromDate(
            startDateMoment
          )

          const endDateMoment = moment(
            `${studyTypeEnd}${endYear}`,
            'DD-MM-YYYY'
          ).toDate()
          const endDateTimestamp = firebase.firestore.Timestamp.fromDate(
            endDateMoment
          )

          const genIntake = {
            title: `${cityName} ${studyTypeName}${commenceSpace}${studyTypeCommence} ${year}`,
            campus: cityName,
            code: `${cityCode}-${studyTypeCode.toUpperCase()}${commenceDash}${studyTypeCommenceCode}-${year}`,
            courseType: studyTypeName,
            startDate: startDateTimestamp,
            endDate: endDateTimestamp,
          }
          genIntakes.push(genIntake)
        }
      }
    }
  }
  return genIntakes
}
