import React from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default ({ doc }) => {
  const { fullName, email } = doc.data()

  return (
    <Box
      border="bottom"
      justify="between"
      direction="row"
      pad={{
        vertical: 'medium',
      }}
    >
      <Box>
        <Title>
          <Link to={`/teachers/${doc.id}`}>
            {fullName} ({email})
          </Link>
        </Title>
      </Box>
    </Box>
  )
}

const Title = styled.div`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.global.colors.black};
    &:hover {
      color: ${(props) => props.theme.global.colors.black};
    }
  }
`
