import React, { Component } from 'react'
import { Box, Button, Form, FormField } from 'grommet'
import styled from 'styled-components'

const INITIAL_STATE = {
  fullName: '',
  email: '',
  filemaker: '',
  creating: false,
  error: null,
}

export default class AddForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit(e) {
    const { email, fullName, filemaker } = this.state
    this.setState({ creating: true })

    const roles = {
      admin: false,
      student: true,
    }

    this.props.firebase
      .newStudent({
        fullName,
        email,
        filemaker,
        roles,
      })
      .then(doc => {
        this.setState({ creating: false })
        this.props.onCreated(doc)
      })
      .catch(error => {
        this.setState({ error })
      })

    e.preventDefault()
  }

  render() {
    const { email, fullName, creating, error } = this.state

    return (
      <Wrap isCreating={creating}>
        <Form onSubmit={this.onSubmit}>
          <FormField
            label="Name"
            name="fullName"
            value={fullName}
            onChange={this.onChange}
            placeholder="Full Name"
            required
          />
          <FormField
            label="Email"
            name="email"
            value={email}
            type="email"
            onChange={this.onChange}
            placeholder="email@company.com"
            required
          />
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button type="submit" label="Create" primary />
          </Box>
          {error && <p>{error.message}</p>}
        </Form>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  opacity: ${props => (props.isCreating ? `0.5` : `1`)};
`
