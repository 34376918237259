import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import Header from './Header'
import List from './List'
import Loader from '../Global/Loader'

const MAX_RESULTS = 40
const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  fetchErrors: null,
  data: [],
}

export default ({ firebase }) => {
  const [status, setStatus] = useState(INITIAL_STATE)
  const { fetching, fetched, data } = status

  useEffect(() => {
    let isCancelled = false

    const fetchRecent = async () => {
      setStatus(prevState => ({
        ...prevState,
        fetching: true,
      }))
      const res = await firebase.intakesRecent(MAX_RESULTS).catch(err => {
        console.log('Error:', err)
      })

      if (!isCancelled) {
        setStatus(prevState => ({
          ...prevState,
          fetched: true,
          fetching: false,
          data: res.docs ? res.docs : [],
        }))
      }
    }
    fetchRecent()

    return () => {
      isCancelled = true
    }
  }, [firebase])

  let body
  if (fetching && !fetched) {
    body = <Loader />
  } else if (fetched) {
    if (data.length > 0) {
      body = <List docs={data} firebase={firebase} />
    } else {
      body = (
        <Box
          border="bottom"
          justify="between"
          direction="row"
          pad={{
            vertical: 'medium',
          }}
        >
          No intakes found.
        </Box>
      )
    }
  }

  return (
    <Box>
      <Header slug="/intakes" />
      {body}
    </Box>
  )
}
