import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Link, withRouter, Route, Switch } from 'react-router-dom'
import { Heading, Box, Menu, defaultProps } from 'grommet'
import { IceCream, Emoji } from 'grommet-icons'
import { compose } from 'recompose'
import styled from 'styled-components'
import * as ROUTES from '../../constants/routes'
import _ from 'lodash'
import moment from 'moment'
import { Float } from '../../util/theme'
import Header from './Header'
import StudentsList from './StudentList'
import StudentsAdd from './Students'
import StudentsAddCsv from './Students/AddCsv.js'
import StudentsAddManual from './Students/AddManual.js'

class IntakeViewBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      activeView: 'list',
      intake: null,
      deleted: false,
      deleting: false,
      deleteError: null,
    }
    this.changeView = this.changeView.bind(this)
    this.deleteIntake = this.deleteIntake.bind(this)
  }

  componentDidMount() {
    this.fetchIntake()
  }

  fetchIntake() {
    const intakeId = this.props.match.params.intake
    this.props.firebase.intake(intakeId).then(snapshot => {
      this.setState({
        loading: false,
        intake: snapshot,
      })
    })
  }

  changeView(view) {
    this.setState({
      activeView: view,
      loading: true,
      intake: null,
    })
    this.fetchIntake()
  }

  deleteIntake() {
    const r = window.confirm(
      'Are you sure? Note: This will not delete students, only the intake.'
    )

    if (r === true) {
      this.setState({
        deleting: true,
      })

      const { firebase } = this.props
      const intakeId = this.props.match.params.intake

      firebase
        .intakeDelete(intakeId)
        .then(() => {
          console.log('DELETED!')
          this.setState({
            deleting: false,
            deleted: true,
          })
        })
        .catch(error => {
          this.setState({
            deleteError: error,
            deleting: false,
          })
        })
    }
  }

  render() {
    const { loading, intake, activeView, deleting, deleted } = this.state
    const { firebase, history } = this.props

    console.log('intake', intake)

    let body
    if (loading)
      body = (
        <Float>
          <IceCream color="dark-2" size="medium" />
        </Float>
      )
    else {
      if (deleted) {
        const viewLink = `/intakes/`
        body = (
          <Status>
            <Box margin={{ top: 'medium' }} direction="row" gap="small">
              <Emoji color="status-ok" size="medium" /> Deleted successfully
              <Link to={viewLink}>Back to Intakes</Link>
            </Box>
          </Status>
        )
      } else if (intake.exists) {
        body = (
          <Box>
            <Wrap isDeleting={deleting}>
              <IntakeViewDetail
                doc={intake}
                firebase={firebase}
                history={history}
                activeView={activeView}
                changeView={this.changeView}
                deleteIntake={this.deleteIntake}
              />
            </Wrap>
          </Box>
        )
      } else {
        body = <Box>Intake not found :(</Box>
      }
    }

    return (
      <Box>
        <Header />
        {body}
      </Box>
    )
  }
}

const IntakeViewDetail = ({
  doc,
  firebase,
  activeView,
  changeView,
  deleteIntake,
  history,
}) => {
  if (!doc.data()) return null

  const { title, code, startDate, endDate, courseType, campus } = doc.data()
  const intakeId = doc.id

  const intakeData = {
    Campus: campus,
    Type: courseType,
    'Start Date': moment(startDate.seconds, 'X').format('DD-MM-YYYY'),
    'End Date': moment(endDate.seconds, 'X').format('DD-MM-YYYY'),
    Title: title,
    Code: code,
  }

  let c = 0
  const intakeTable = _.map(intakeData, (value, key) => {
    c++
    return (
      <Box width={'large'} direction="row" justify="start" key={c}>
        <Box width={'small'}>
          <Heading
            alignSelf="start"
            margin={{ top: 'xsmall', bottom: 'xsmall' }}
            color="dark-2"
            level="4"
          >
            {key}
          </Heading>
        </Box>
        <Box width={'medium'}>{value}</Box>
      </Box>
    )
  })

  return (
    <Box
      justify="start"
      direction="column"
      pad={{
        top: 'medium',
        bottom: 'xlarge',
      }}
    >
      <Box direction="row" justify="between">
        <Box>
          <Heading
            alignSelf="start"
            margin={{ top: 'xsmall', bottom: 'large' }}
            color="dark-2"
            level="3"
          >
            {title}
          </Heading>
        </Box>
        <Box>
          <Menu
            label="Actions"
            items={[
              {
                label: 'View Students',
                onClick: e => history.push(`/intakes/${intakeId}/`),
              },
              {
                label: 'Add Students',
                onClick: e => history.push(`/intakes/${intakeId}/add-students`),
              },
              {
                label: 'Edit Intake',
                onClick: e => history.push(`/intakes/${intakeId}/edit`),
              },
              { label: 'Delete Intake', onClick: e => deleteIntake() },
            ]}
          />
        </Box>
      </Box>

      <Box margin={{ bottom: 'large' }}>{intakeTable}</Box>

      <Switch>
        <Route
          exact
          path={ROUTES.INTAKE_VIEW}
          render={props => (
            <>
              <StudentsList {...props} intake={doc} firebase={firebase} />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.INTAKE_ADD_STUDENTS}
          component={StudentsAdd}
        />
        <Route
          exact
          path={ROUTES.INTAKE_ADD_STUDENTS_CSV}
          render={props => (
            <StudentsAddCsv {...props} intake={doc} firebase={firebase} />
          )}
        />
        <Route
          exact
          path={ROUTES.INTAKE_ADD_STUDENTS_MANUAL}
          render={props => (
            <StudentsAddManual {...props} intake={doc} firebase={firebase} />
          )}
        />
      </Switch>
    </Box>
  )
}

//{view}

const condition = authUser => !!authUser
//export default withAuthorization(condition)(IntakeView);

const IntakeView = compose(
  withAuthorization(condition),
  withRouter
)(IntakeViewBase)

export default IntakeView

const Wrap = styled.div`
  opacity: ${props => (props.isDeleting ? `0.5` : `1`)};
`

const Status = styled.div`
  a {
    color: ${defaultProps.theme.global.colors.brand};
    &:hover {
      color: ${defaultProps.theme.global.colors.black};
    }
  }
`
