import React, { Component } from 'react'
import { Box, Button, FormField, Calendar, TextInput, Select } from 'grommet'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormError } from '../Global/Components'
import { campuses, courseTypes } from '../../util/siteConfig'
import {
  getShortCodeFromCourseType,
  getShortCodeFromCity,
} from '../../util/helpers'
// import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  data: {
    title: '',
    code: '',
    startDate: {
      seconds: moment().format('X'),
    },
    endDate: {
      seconds: moment().format('X'),
    },
    courseType: courseTypes[0],
    campus: campuses[0],
  },
  courseTypeOptions: courseTypes,
  campusOptions: campuses,
  startDateToggled: false,
  endDateToggled: false,
  submitting: false,
  formErrors: {},
  saveErrors: null,
}

console.log('campuses', campuses)
console.log('courseTypes', courseTypes)

const intakeSchema = Yup.object().shape({
  campus: Yup.string().required('Campus is required'),
  courseType: Yup.string().required('Course type is required'),
  startDate: Yup.object()
    .test({
      name: 'startDate',
      message: 'Date format is invalid',
      test: (value) => {
        return value.seconds
      },
    })
    .required('End date is required'),
  endDate: Yup.object()
    .test({
      name: 'endDate',
      message: 'Date format is invalid',
      test: (value) => {
        return value.seconds
      },
    })
    .required('End date is required'),
  title: Yup.string().required('Title is required'),
  code: Yup.string().required('Code is required'),
})

export default class IntakeForm extends Component {
  state = { ...INITIAL_STATE }

  componentWillMount = () => {
    const { data } = this.props
    if (data) {
      this.setState({
        data: data,
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.state.submitting && nextProps.submitted) {
      this.setState({
        submitting: false,
      })
    }
  }

  onSubmit = (values) => {
    // const { data } = this.state
    this.setState({
      submitting: true,
    })
    this.props.onSubmit(values)
  }

  onCampusChange = (campus, currValues) => {
    currValues.campus = campus
    return {
      code: '',
      title: '',
      campus: campus,
    }
  }

  onTypeChange = (courseType, currValues) => {
    currValues.courseType = courseType

    return {
      code: '',
      title: '',
      campus: courseType,
    }
  }

  onDateSelect = (dateKey, date, setFieldValue, currValues) => {
    const { firebase } = this.props
    const toggleKey = `${dateKey}Toggled`

    // Store as Firestore timestamp
    const dateMoment = moment(date).toDate()
    const dateTimestamp = firebase.firestore.Timestamp.fromDate(dateMoment)

    const formatted = moment(date).format('YYYY-MM-DD')

    const formattedField =
      dateKey === 'startDate' ? 'formattedStart' : 'formattedEnd'

    currValues[dateKey] = dateTimestamp

    console.log(dateKey, date)

    setFieldValue(dateKey, dateTimestamp)
    setFieldValue(formattedField, formatted)

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [dateKey]: dateTimestamp,
      },
    }))

    this.toggleDateField(toggleKey)
  }

  toggleDateField = (calKey) => {
    const val = this.state[calKey]
    this.setState({ [calKey]: !val })
  }

  inputChange = (e, key) => {
    const val = e.target.value

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [key]: val,
      },
    }))
  }

  render = () => {
    const {
      data,
      courseTypeOptions,
      campusOptions,
      startDateToggled,
      endDateToggled,
      submitting,
      formErrors,
    } = this.state
    const { startDate, endDate, courseType, campus, title, code } = data
    const { error, submitLabel, firebase } = this.props

    // Firestore returns object → Timestamp { seconds: xxxxxxxxxx }
    const formattedStart = moment(startDate.seconds, 'X').format('YYYY-MM-DD')
    const formattedEnd = moment(endDate.seconds, 'X').format('YYYY-MM-DD')

    const renderCalendar = (key, date, visible, setFieldValue, currValues) => {
      if (visible) {
        const selected = moment(date.seconds, 'X').format('YYYY-MM-DD HH:mm Z')
        return (
          <Calendar
            size="small"
            date={selected}
            onSelect={(date) =>
              this.onDateSelect(key, date, setFieldValue, currValues)
            }
          />
        )
      } else {
        return null
      }
    }

    return (
      <Wrap isSubmitting={submitting}>
        <Formik
          initialValues={{
            campus: campus,
            courseType: courseType,
            startDate: startDate,
            formattedStart: formattedStart,
            endDate: endDate,
            formattedEnd: formattedEnd,
            title: title,
            code: code,
          }}
          validateOnChange={false}
          validationSchema={intakeSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log('WUT A')
            this.onSubmit(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              onSubmit={(e) => {
                console.log('WUT B')
                e.preventDefault()
                handleSubmit()
              }}
            >
              <FormField label="Campus" error={errors.campus}>
                <Select
                  id="campus"
                  name="campus"
                  placeholder="Select"
                  value={values.campus}
                  options={campusOptions}
                  onChange={(e) => {
                    setFieldValue('campus', e.value)
                  }}
                />
              </FormField>
              {errors.campus ? <FormError>{errors.campus}</FormError> : null}
              <FormField label="Type">
                <Select
                  id="courseType"
                  name="courseType"
                  placeholder="Select"
                  value={values.courseType}
                  options={courseTypeOptions}
                  onChange={(e) => {
                    setFieldValue('courseType', e.value)
                  }}
                />
              </FormField>
              {errors.courseType ? (
                <FormError>{errors.courseType}</FormError>
              ) : null}
              <FormField label="Start Date">
                <TextInput
                  name="startDate"
                  value={values.formattedStart}
                  placeholder=""
                  required
                  onClick={(e) => this.toggleDateField('startDateToggled')}
                />
              </FormField>
              {renderCalendar(
                'startDate',
                values.startDate,
                startDateToggled,
                setFieldValue,
                values
              )}
              {errors.startDate ? (
                <FormError>{errors.startDate}</FormError>
              ) : null}
              <FormField label="End Date">
                <TextInput
                  name="endDate"
                  value={values.formattedEnd}
                  placeholder=""
                  required
                  onClick={(e) => this.toggleDateField('endDateToggled')}
                />
              </FormField>
              {renderCalendar(
                'endDate',
                values.endDate,
                endDateToggled,
                setFieldValue,
                values
              )}
              {errors.endDate ? <FormError>{errors.endDate}</FormError> : null}
              <br />
              <br />
              <FormField label="Title">
                <TextInput
                  requried
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </FormField>
              {errors.title ? <FormError>{errors.title}</FormError> : null}
              <FormField label="Code">
                <TextInput
                  requried
                  placeholder="CODE"
                  name="code"
                  value={values.code}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </FormField>
              {errors.code ? <FormError>{errors.code}</FormError> : null}
              <Box
                direction="row"
                justify="between"
                margin={{ top: 'medium', bottom: 'large' }}
              >
                <Button type="submit" label={submitLabel} primary />
              </Box>
            </form>
          )}
        </Formik>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  opacity: ${(props) => (props.isSubmitting ? `0.5` : `1`)};
`
