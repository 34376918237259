import React from 'react'
import SignOutButton from '../SignOut'
import { AuthUserContext } from '../Session'
import * as ROUTES from '../../constants/routes'
import { Link } from 'react-router-dom'
import { Box, Heading } from 'grommet'
//import { Home } from 'grommet-icons'
import styled from 'styled-components'

const Navigation = ({ authUser }) => {
  return (
    <Nav>
      <Box
        direction="row"
        pad={{
          horizontal: 'medium',
          vertical: 'small',
        }}
        justify="center"
      >
        <ContentWrap>
          <Box direction="column" pad="medium">
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser ? (
                  <NavigationAuth authUser={authUser} />
                ) : (
                  <NavigationUnAuth />
                )
              }
            </AuthUserContext.Consumer>
          </Box>
        </ContentWrap>
      </Box>
    </Nav>
  )
}

const NavigationAuth = ({ authUser }) => {
  return (
    <Box direction="column" justify="center">
      <Box direction="row" justify="between">
        <Box direction="row" justify="center" align="center" gap="medium">
          <HeadingLink margin="none" level="4">
            <Link to={ROUTES.INTAKES}>Intakes</Link>
          </HeadingLink>
          <HeadingLink margin="none" level="4">
            <Link to={ROUTES.STUDENTS}>Students</Link>
          </HeadingLink>
          <HeadingLink margin="none" level="4">
            <Link to={ROUTES.TEACHERS}>Teachers</Link>
          </HeadingLink>
        </Box>
        <Box direction="row" justify="center" align="center">
          <Email>
            {authUser.fullName} - {authUser.email}
          </Email>{' '}
          <SignOutButton />
        </Box>
      </Box>
    </Box>
  )
}

const NavigationUnAuth = () => {
  return (
    <Box direction="column" justify="center">
      <Box direction="row" justify="between">
        <Box direction="row" justify="center" align="center">
          <>&nbsp;</>
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation

const Nav = styled.div`
  background: ${(props) => props.theme.global.colors['light-1']};
`

const HeadingLink = styled(Heading)`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.global.colors.black};
    &:hover {
      color: ${(props) => props.theme.global.colors.brand};
    }
  }
`

const Email = styled.span`
  margin-right: 1em;
  color: ${(props) => props.theme.global.colors['dark-6']};
`

const ContentWrap = styled.div`
  max-width: 1400px;
  width: 100%;
`
