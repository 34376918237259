import React, { Component } from 'react'
import { Clipboard } from 'grommet-icons'
import { Float } from '../../util/theme'
//import styled from 'styled-components';
import { Heading, Box } from 'grommet'
import IntakeListItem from '../Intakes/ListItem'

export default class Intakes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      intakes: null,
    }
  }

  componentDidMount() {
    const studentId = this.props.student.id
    const { firebase } = this.props

    firebase.db
      .collection('intakes')
      .where(
        `students.${studentId}.addedAt`,
        '<',
        firebase.firestore.Timestamp.fromDate(new Date())
      )
      .get()
      .then(snapshot => {
        this.setState({
          loading: false,
          intakes: snapshot,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error,
        })
      })
  }

  render() {
    const { intakes, loading } = this.state

    if (loading) {
      return (
        <Float>
          <Clipboard color="dark-2" size="medium" />
        </Float>
      )
    }

    let body
    if (intakes.docs.length > 0) {
      body = intakes.docs.map((doc, i) => <IntakeListItem key={i} doc={doc} />)
    } else {
      body = <p>Hasn't been added to any intakes.</p>
    }

    return (
      <Box
        border={{
          color: 'black',
          size: 'small',
          style: 'solid',
          side: 'top',
        }}
      >
        <Box direction="row" justify="between" align="center">
          <Heading
            alignSelf="start"
            margin={{ top: 'medium', bottom: 'medium' }}
            color="dark-2"
            level="3"
          >
            Intakes
          </Heading>
        </Box>
        {body}
      </Box>
    )
  }
}
