import React from 'react'
import { Heading, Box, Button, Text } from 'grommet'
import styled from 'styled-components'
import { Checkmark } from 'grommet-icons'

const AddCsvPreview = ({ data, onSubmit, studentsCreatedEmails, isAdding }) => {
  if (!data) return null

  let body, action

  // Basic check that the data is structured correctly
  if (data[0].fullName && data[0].email && data[0].filemaker) {
    const wording = isAdding ? `Importing` : `Import Students`
    action = (
      <Box
        direction="column"
        align="start"
        margin={{ top: 'medium', bottom: 'medium' }}
      >
        <Status isAdding={isAdding}>
          <p>File looks good, lets do this!</p>
          <Button label={wording} onClick={e => onSubmit(e)} />
        </Status>
      </Box>
    )
    body = (
      <AddCsvPreviewItems
        students={data}
        studentsCreatedEmails={studentsCreatedEmails}
      />
    )
  } else {
    body = (
      <Box>
        <p>
          Looks like your data isn't formatted correctly. Please check you have
          columns for "fullName", "email" and "filemaker"
        </p>
      </Box>
    )
  }

  return (
    <Box>
      {action}
      <Box
        border={{
          color: 'black',
          size: 'small',
          style: 'solid',
          side: 'top',
        }}
        margin={{ top: 'large' }}
      >
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ bottom: 'medium' }}
        >
          <Heading
            alignSelf="start"
            margin={{ top: 'medium', bottom: 'medium' }}
            color="dark-2"
            level="3"
          >
            Preview
          </Heading>
        </Box>

        <Box justify="between" direction="row" align="center">
          <Box width="40%">
            <Text color="dark-6">Full Name</Text>
          </Box>
          <Box width="40%">
            <Text color="dark-6">Email</Text>
          </Box>
          <Box width="20%">
            <Text color="dark-6">Filemaker</Text>
          </Box>
        </Box>

        {body}
      </Box>
    </Box>
  )
}

const AddCsvPreviewItems = ({ students, studentsCreatedEmails }) => {
  return students.map((student, i) => {
    const added = studentsCreatedEmails.indexOf(student.email) > -1
    const icon = added ? <Checkmark /> : ''
    return (
      <Wrap added={added} key={i}>
        <Box
          border="bottom"
          justify="between"
          direction="row"
          align="center"
          pad={{
            vertical: 'medium',
          }}
        >
          <Box width="40%" direction="row" gap="xsmall">
            {icon} {student.fullName}
          </Box>
          <Box width="40%">{student.email}</Box>
          <Box width="20%">{student.filemaker}</Box>
        </Box>
      </Wrap>
    )
  })
}

export default AddCsvPreview

const Wrap = styled.div`
  opacity: ${props => (props.added ? `0.5` : `1`)};
`

const Status = styled.div`
  opacity: ${props => (props.isAdding === true ? `0.5` : `1`)};
`
