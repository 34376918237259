import React, { Component } from 'react'
import { withFirebase } from '../Firebase'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box, Button, defaultProps } from 'grommet'

class StudentListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteSent: false,
      inviteSending: false,
      inviteFetching: false,
      inviteFetched: true,
      inviteError: null,
      invite: null,
      loading: true,
    }
    this.onSendInvite = this.onSendInvite.bind(this)
  }

  componentDidMount() {
    this.fetchInvite()
  }

  fetchInvite = async () => {
    // Fetch invite from database, only if needed (has adminAddedAt and no confirmedAt value)

    const self = this
    this.setState({
      inviteFetching: true,
    })

    const { firebase } = this.props
    const uid = 'XXXXXXXXX'
    firebase.invite(uid).then(() => {
      self.setState({
        inviteFetching: false,
        inviteFetched: true,
        invite: null,
      })
    })
  }

  onSendInvite(e) {
    const { intake, student, firebase } = this.props
    const studentId = student.id
    const intakeId = intake.id
    const { email, fullName } = this.props.student.data()

    this.setState({ inviteSending: true, inviteSent: false, inviteError: null })

    const sendInviteFunc = this.props.firebase.functions.httpsCallable(
      'sendInvite'
    )
    const sendInvite = sendInviteFunc({ studentId, email, fullName })

    const intakeRef = firebase.db.collection('intakes').doc(intakeId)
    const intakeUpdate = intakeRef.update({
      [`students.${studentId}.invitedAt`]: firebase.firestore.Timestamp.fromDate(
        new Date()
      ),
      [`students.${studentId}.inviteAcceptedAt`]: null,
    })

    const userRef = firebase.db.collection('users').doc(studentId)
    const userUpdate = userRef.update({
      invitedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      inviteAcceptedAt: null,
    })

    Promise.all([sendInvite, userUpdate, intakeUpdate])
      .then(([sendResult, userUpdateResult, intakeUpdateResult]) => {
        this.setState({ inviteSending: false, inviteSent: true })
      })
      .catch(error => {
        this.setState({ inviteSending: false, inviteError: error })
      })

    e.preventDefault()
  }

  render() {
    const { inviteSending, inviteFetched, invite } = this.state
    const { student, isRemoved, intake } = this.props
    const { fullName, email } = student.data()

    const intakeStudent = intake.data().students[student.id]
    const { roles } = student.data()

    if (intakeStudent) {
      const { confirmedAt, adminAddedAt } = intakeStudent

      let inviteButton, inviteStatus, buttonText, buttonColor, statusText

      if (inviteFetched) {
        // console.log('STATUS', email, invite, confirmedAt, adminAddedAt)

        let roleType
        if (roles.studentClass && roles.studentClass === true) {
          roleType = 'Class Account'
        } else if (roles.student && roles.student === true) {
          roleType = 'Student'
        }

        if (invite !== null && confirmedAt === undefined) {
          console.log('1. Still waiting on inviate accept')
          // Still waiting on inviate accept...
          statusText = 'Pending...'
          buttonColor = 'status-disabled'
          buttonText = 'Re-send'
        } else if (
          invite !== null &&
          confirmedAt !== undefined &&
          invite.inviteAcceptedAt !== null
        ) {
          console.log('2. Invite accepted')
          // Invite accepted
          statusText = 'Enrolled'
          buttonColor = null
          buttonText = null
        } else if (invite === null && adminAddedAt !== undefined) {
          console.log('3. No invite sent')
          // No invite sent
          statusText = ''
          buttonColor = 'brand'
          buttonText = 'Send invite'
        } else if (adminAddedAt === undefined) {
          console.log('4. Enrolled themselves')
          // Enrolled themselves
          statusText = `${roleType} - Enrolled`
          buttonColor = null
          buttonText = null
        }
      }

      if (statusText !== null) {
        inviteStatus = <Status>{statusText}</Status>
      }
      if (buttonColor !== null && statusText !== null) {
        inviteButton = (
          <Button
            color={buttonColor}
            label={buttonText}
            onClick={this.onSendInvite}
          />
        )
      }

      let actions, opacity
      if (!isRemoved) {
        opacity = inviteSending ? `0.5` : `1`
        actions = (
          <Box direction="row" gap="small" align="center">
            {inviteStatus}
            {inviteButton}
            {/* <Button
              primary
              color="status-disabled"
              icon={<Close size="small" />}
              onClick={e => onRemove(e, student.id)}
            /> */}
          </Box>
        )
      } else {
        opacity = `0.3`
        actions = (
          <Box direction="row" gap="small" align="center">
            <Status>Removed</Status>
          </Box>
        )
      }

      return (
        <Item opacity={opacity}>
          <Box
            border="bottom"
            justify="between"
            direction="row"
            align="center"
            pad={{
              vertical: 'medium',
            }}
          >
            <Box align="center">
              <Title>
                <Link to={`/students/${student.id}`}>
                  {fullName} ({email})
                </Link>
              </Title>
            </Box>
            {actions}
          </Box>
        </Item>
      )
    } else {
      return null
    }
  }
}

export default withFirebase(StudentListItem)

const Item = styled.div`
  opacity: ${props => (props.opacity ? props.opacity : `1`)};
`

const Status = styled.span`
  color: ${defaultProps.theme.global.colors.placeholder};
`

const Title = styled.div`
  a {
    text-decoration: none;
    color: ${defaultProps.theme.global.colors['dark-2']};
    &:hover {
      color: ${defaultProps.theme.global.colors['dark-2']};
    }
  }
`
