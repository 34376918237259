import React from 'react'
//import { Link } from 'react-router-dom'
//import { Box } from 'grommet'
//import * as ROUTES from '../../constants/routes'
import Intakes from '../Intakes'

const Landing = () => {
  return <Intakes />
}

// const Landing = () => {
//   return (
//     <Box direction="row" gap="xsmall" margin={{ top: 'large' }}>
//       Hello!<Link to={ROUTES.SIGN_IN}>Sign In</Link>
//     </Box>
//   )
// }

export default Landing

//const condition = authUser => !!authUser;
//export default withAuthorization(condition)(Landing);
