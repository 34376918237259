import React from 'react';
import styled from 'styled-components';
import { defaultProps, Box, Text } from 'grommet';
import { theme } from '../../util/theme';
import { Link } from 'react-router-dom';

const ListItem = ({ doc }) => {
  const { title } = doc.data();
	return (
    <Box
      border="bottom"
      justify="between"
      direction="row"
      pad={{
        vertical: 'medium'
      }}
    >
      <Box>
        <Title>
          <Link to={`/intakes/${doc.id}`}>
            {title}
          </Link>
        </Title>
      </Box>
      <Box>
        <Cta>
          <Link to={`/intakes/${doc.id}/edit`}>
            <Text weight="bold" size="medium">
              Edit
            </Text>
          </Link>
          <Link to={`/intakes/${doc.id}`}>
            <Text weight="bold" size="medium">
              View
            </Text>
          </Link>
        </Cta>
      </Box>
    </Box>
  );
};

export default ListItem;

const Cta = styled.div`
	a {
		text-decoration: none;
		color: ${defaultProps.theme.global.colors.brand};
		margin-left: 1em;
		&:hover {
			color: ${defaultProps.theme.global.colors.black};
		}
	}
`;

const Title = styled.div`
	a {
		text-decoration: none;
		color: ${theme.global.colors.black};
		&:hover {
			color: ${theme.global.colors.black};
		}
	}
`;
