import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
// import _ from 'lodash'
import { Box, Button } from 'grommet'
import { Cycle, Checkmark, Close } from 'grommet-icons'

const Item = ({
  firebase,
  data,
  handleChecked,
  handleExists,
  handleCreated,
  isRunning,
}) => {
  const { title, campus, code, courseType, startDate, endDate } = data

  const [checked, setChecked] = useState(false)
  const [checking, setChecking] = useState(false)
  const [exists, setExists] = useState(false)
  const [created, setCreated] = useState(false)
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    if (firebase) {
      // if (
      //   code === 'BNE-FT-JANFEB-1997-2005' ||
      //   code === 'SYD-FT-JANFEB-1997-2005' ||
      //   code === 'MEL-FT-JANFEB-1997-2005'
      // ) {
      if (!checked && !checking) {
        // console.log('Checking >>> ', code)
        setChecking(true)
        firebase
          .intakesByCode(code)
          .then(snapshot => {
            console.log('Checked >>> ', code)

            setChecked(true)
            setChecking(false)
            handleChecked(code)

            if (snapshot.docs.length === 1) {
              setExists(true)
              handleExists(code)
            }
          })
          .catch(error => {
            console.log('error', error)
          })
      }
      if (checked && !exists && isRunning && !created && !creating) {
        // console.log('Creating >>> ', code)
        setCreating(true)
        createIntake(data, code)
      }
      //}
    }
  }, [
    firebase,
    checked,
    exists,
    isRunning,
    code,
    handleChecked,
    handleExists,
    checking,
    // eslint-disable-next-line
    createIntake,
    created,
    creating,
    data,
  ])

  // TODO: Fix!
  // eslint-disable-next-line
  const createIntake = (data, code) => {
    firebase
      .intakeCreate(data)
      .then(doc => {
        console.log('Created >>> ', code)
        setCreated(true)
        setCreating(false)
        handleCreated(code)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  // if (code === 'BNE-FT-JANFEB-1997-2005') {
  //   console.log('checked', checked)
  //   console.log('created', created)
  //   console.log('exists', exists)
  // }

  let icon = <Cycle />
  let cta = ''
  if (checked) {
    if (created || exists) {
      icon = <Checkmark color="brand" />
    } else if (!exists) {
      icon = <Close color="status-unknown" />
      cta = (
        <Button
          label="Create"
          margin={{ horizontal: 'medium' }}
          onClick={() => {
            createIntake(data, code)
          }}
        />
      )
    }
  }

  const opacity = created || exists ? `1` : `0.8`
  const startFormated = moment(startDate.seconds, 'X').format('DD-MM-YYYY')
  const endFormated = moment(endDate.seconds, 'X').format('DD-MM-YYYY')

  return (
    <Wrap opacity={opacity}>
      <Box
        border="bottom"
        justify="between"
        direction="row"
        align="start"
        pad={{
          vertical: 'medium',
        }}
      >
        <Box align="start" width="medium">
          <Title>{title}</Title>
        </Box>
        <Box align="start" width="small">
          <Mini>
            {code}
            <br />
            {campus}
            <span> | </span>
            {courseType}
            <br />
            {startFormated} to {endFormated}
          </Mini>
        </Box>
        <Box align="center" width="small" direction="row" justify="end">
          {cta}
          {icon}
        </Box>
      </Box>
    </Wrap>
  )
}

export default Item

const Wrap = styled.div`
  opacity: ${props => (props.opacity ? props.opacity : `1`)};
`

const Title = styled.div``

const Mini = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  pre {
    margin: 0;
    padding: 0;
    font-family: monaco;
  }
  span {
    color: #999;
  }
`
