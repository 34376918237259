import React from 'react'
import { Java } from 'grommet-icons'
import { Float } from '../../util/theme'
import { Box } from 'grommet'

export default () => {
  return (
    <Float>
      <Box
        pad={{
          vertical: 'small',
        }}
      >
        <Java color="dark-2" size="medium" />
      </Box>
    </Float>
  )
}
