import React, { Component } from 'react'
import { Heading, Box, Button, Form, FormField, CheckBox } from 'grommet'
import styled from 'styled-components'
import { StatusGood } from 'grommet-icons'
import { Link } from 'react-router-dom'

const INITIAL_STATE = {
  fullName: '',
  email: '',
  password: '',
  isClassAccount: false,
  creating: false,
  created: false,
  createError: null,
  student: null,
}

class AddManual extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onChange = event => {
    if (event.target.name === 'isClassAccount') {
      console.log(event.target.value)

      this.setState({
        [event.target.name]: !this.state.isClassAccount,
      })
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  createStudent = async () => {
    const { firebase, intake } = this.props
    const createdVia = 'admin-manual'

    const { email, fullName, password, isClassAccount } = this.state
    const user = {
      fullName,
      email,
      password,
      isClassAccount,
      createdVia,
    }

    const userCreateFunc = firebase.functions.httpsCallable('dbUserCreate')
    const funcData = {
      user: user,
      intake: {
        id: intake.id,
      },
      createdVia: createdVia,
    }

    console.log('>>', funcData)

    this.setState({ creating: true })

    userCreateFunc(funcData)
      .then(res => {
        console.log('✔ Account created', res)
        this.setState({
          creating: false,
          created: true,
          createError: null,
        })
      })
      .catch(error => {
        const { message } = error
        console.log('>>>', message)
        this.setState({
          creating: false,
          createError: message,
        })
      })

    // const { firebase, intake } = this.props
    // const intakeId = intake.id
    // const { email, fullName, password, isClassAccount } = this.state

    // this.setState({ creating: true })

    // const now = firebase.firestore.Timestamp.fromDate(new Date())
    // const createdAt = now
    // const updatedAt = now
    // const createdVia = 'admin-manual'

    // const roles = {
    //   admin: false,
    //   student: true,
    // }

    // const student = {
    //   fullName,
    //   email,
    //   password,
    //   isClassAccount,
    //   roles,
    //   createdAt,
    //   updatedAt,
    //   createdVia,
    // }

    // console.log('createStudent CREATE', student.email)

    // let createStudent
    // try {
    //   createStudent = await firebase.newStudent(student)
    // } catch (error) {
    //   this.setState({
    //     createError: error,
    //   })
    // }

    // console.log('createStudent CREATED', createStudent.id)

    // try {
    //   await firebase.intake(intakeId)
    // } catch (error) {
    //   this.setState({
    //     createError: error,
    //   })
    // }

    // let intakeStudents = intake.data().students

    // if (intakeStudents) {
    //   if (!intakeStudents[createStudent.id]) {
    //     intakeStudents[createStudent.id] = {
    //       addedAt: now,
    //       adminAddedAt: now,
    //     }
    //   }
    // } else {
    //   intakeStudents = {}
    //   intakeStudents[createStudent.id] = {
    //     addedAt: now,
    //     adminAddedAt: now,
    //   }
    // }

    // console.log('intakeUpdate UPDATE')

    // await firebase.intakeUpdate(intake.id, {
    //   students: intakeStudents,
    // })

    // console.log('intakeUpdate UPDATED')

    // this.setState({
    //   creating: false,
    //   created: true,
    //   createError: null,
    //   student: createStudent,
    // })
  }

  onSubmit = e => {
    this.createStudent()
    e.preventDefault()
  }

  render() {
    const {
      email,
      fullName,
      password,
      isClassAccount,
      creating,
      created,
      createError,
    } = this.state
    const { intake } = this.props

    let body
    if (created) {
      const viewLink = `/intakes/${intake.id}`
      body = <AddComplete viewLink={viewLink} onClick={this.onReset} />
    } else {
      const formData = { email, fullName, password, isClassAccount }
      body = (
        <AddForm
          creating={creating}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          createError={createError}
          data={formData}
        />
      )
    }

    return (
      <Box>
        <Box
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'top',
          }}
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Add Student
              </Heading>
            </Box>
          </Box>
          {body}
        </Box>
      </Box>
    )
  }
}

export default AddManual

const AddComplete = ({ viewLink, onClick }) => {
  return (
    <Box>
      <Box direction="row" gap="xsmall" margin={{ bottom: 'medium' }}>
        <StatusGood /> Done! <Link to={viewLink}>View Students</Link>
      </Box>
      <Box direction="row">
        <Button label="Add another" onClick={onClick} />
      </Box>
    </Box>
  )
}

const AddForm = ({ creating, onSubmit, onChange, createError, data }) => {
  console.log('>>>', data.isClassAccount)

  let passwordContent
  if (data.isClassAccount) {
    passwordContent = (
      <FormField
        label="Password"
        name="password"
        value={data.password}
        type="password"
        onChange={onChange}
        placeholder="pass"
        required
      />
    )
  }

  return (
    <Box margin={{ top: 'xsmall', bottom: 'xsmall' }} width="medium">
      <Wrap isCreating={creating}>
        <Form onSubmit={onSubmit}>
          <FormField
            label="Name"
            name="fullName"
            value={data.fullName}
            onChange={onChange}
            placeholder="Full Name"
            required
          />
          <FormField
            label="Email"
            name="email"
            value={data.email}
            type="email"
            onChange={onChange}
            placeholder="email@company.com"
            required
          />
          <Box margin={{ top: 'medium', bottom: 'small' }}>
            <CheckBox
              label="This is a shared class account"
              checked={data.isClassAccount}
              onChange={onChange}
              name="isClassAccount"
            />
          </Box>
          {passwordContent}
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button type="submit" label="Create" primary />
          </Box>
          {createError && (
            <Errors>
              <p>{createError}</p>
            </Errors>
          )}
        </Form>
      </Wrap>
    </Box>
  )
}

const Wrap = styled.div`
  opacity: ${props => (props.isCreating ? `0.5` : `1`)};
`

const Errors = styled.div`
  color: ${props => props.theme.global.colors['status-error']};
`
