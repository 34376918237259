import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withFirebase } from '../Firebase'
import { Box, Button, Form, FormField, Heading } from 'grommet'
import * as ROUTES from '../../constants/routes'

const SignInPage = ({ authUser }) => {
  if (authUser) return null

  return (
    <Box width="medium">
      <Box direction="row" justify="between" align="center">
        <Heading
          alignSelf="start"
          margin={{ vertical: 'large' }}
          color="dark-2"
          level="1"
        >
          Sign-in
        </Heading>
      </Box>
      <SignInForm />
    </Box>
  )
}

const INITIAL_STATE = {
  email: '',
  password: '',
  creating: false,
  error: null,
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state

    this.setState({
      creating: true,
    })

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.INTAKES)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error, creating } = this.state
    //const isInvalid = password === '' || email === '';

    return (
      <Wrap isCreating={creating}>
        <Form onSubmit={this.onSubmit}>
          <FormField
            label="Email"
            name="email"
            value={email}
            type="email"
            onChange={this.onChange}
            placeholder="email@company.com"
            required
          />
          <FormField
            label="Password"
            name="password"
            value={password}
            type="password"
            onChange={this.onChange}
            placeholder="******"
            required
          />
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button type="submit" label="Sign-in" primary />
          </Box>
          {error && <p>{error.message}</p>}
        </Form>
      </Wrap>
    )
  }
}

/*
<form onSubmit={this.onSubmit}>
  <input
    name="email"
    value={email}
    onChange={this.onChange}
    type="text"
    placeholder="Email Address"
  />
  <input
    name="password"
    value={password}
    onChange={this.onChange}
    type="password"
    placeholder="Password"
  />
  <button disabled={isInvalid} type="submit">
    Sign In
  </button>

  {error && <p>{error.message}</p>}
</form>
*/

const SignInForm = compose(
  withRouter,
  withFirebase
)(SignInFormBase)

export default SignInPage

export { SignInForm }

const Wrap = styled.div`
  opacity: ${props => (props.isCreating ? `0.5` : `1`)};
`
