import React, { Component } from 'react'
import { withAuthorization } from '../Session'
import { Heading, Box, Button } from 'grommet'
import Header from './Header'
import AddForm from './AddForm'
import { StatusGood } from 'grommet-icons'
import { Link } from 'react-router-dom'

const INITIAL_STATE = {
  created: false,
  teacher: null,
}

class TeacherAdd extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.onCreated = this.onCreated.bind(this)
    this.onReset = this.onReset.bind(this)
  }

  onCreated(id) {
    this.setState({
      created: true,
      teacher: id,
    })
  }

  onReset() {
    this.setState({
      created: false,
    })
  }

  render() {
    const { created, teacher } = this.state

    let body
    if (created) {
      const viewLink = `/teachers/${teacher}`
      body = (
        <Box>
          <Box direction="row" gap="xsmall" margin={{ bottom: 'medium' }}>
            <StatusGood />
            Done! <Link to={viewLink}>View Teacher</Link>
          </Box>
          <Box direction="row">
            <Button label="Add another" onClick={this.onReset} />
          </Box>
        </Box>
      )
    } else {
      body = (
        <AddForm
          onChange={this.onChange}
          onCreated={this.onCreated}
          firebase={this.props.firebase}
        />
      )
    }

    return (
      <Box>
        <Header />

        <Box
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'top',
          }}
          justify="start"
          direction="column"
          pad={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Box direction="row" justify="between">
            <Box>
              <Heading
                alignSelf="start"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                color="dark-2"
                level="3"
              >
                Add
              </Heading>
            </Box>
          </Box>
        </Box>

        <Box width="medium">{body}</Box>
      </Box>
    )
  }
}

const condition = (authUser) => !!authUser
export default withAuthorization(condition)(TeacherAdd)
