import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SignInPage from '../SignIn'
import LandingPage from '../Landing'

import ToolsPage from '../Tools'

import IntakesPage from '../Intakes'
import IntakesFilterPage from '../Intakes/Filter'
import IntakesAddPage from '../Intakes/Add'
import IntakeViewPage from '../Intakes/View'
import IntakeEditPage from '../Intakes/Edit'

import StudentsPage from '../Students'
import StudentsFilterPage from '../Students/Filter'
import StudentsAddPage from '../Students/Add'
import StudentsSearchPage from '../Students/Search'
import StudentsAddCsvPage from '../Students/AddCsv'
import StudentsViewPage from '../Students/View'
import StudentsEditPage from '../Students/Edit'

import TeachersPage from '../Teachers'
import TeachersAddPage from '../Teachers/Add'
import TeachersViewPage from '../Teachers/View'

import HomePage from '../Home'
import Navigation from '../Navigation'
import styled from 'styled-components'

import { Box, Grommet } from 'grommet'
import { theme } from '../../util/theme'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Session'

class App extends Component {
  render() {
    return (
      <Router>
        <Grommet theme={theme}>
          <Navigation />
          <Box
            direction="row"
            pad={{
              horizontal: 'medium',
              vertical: '0',
              bottom: 'xlarge',
            }}
            justify="center"
          >
            <ContentWrap>
              <Box
                direction="column"
                pad={{
                  horizontal: 'medium',
                  vertical: '0',
                  bottom: 'xlarge',
                }}
              >
                <Page>
                  <Switch>
                    <Route
                      exact
                      path={ROUTES.LANDING}
                      component={LandingPage}
                    />
                    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route
                      path={ROUTES.INTAKES}
                      exact
                      component={IntakesPage}
                    />
                    <Route
                      path={ROUTES.INTAKES_FILTER}
                      exact
                      component={IntakesFilterPage}
                    />
                    <Route
                      path={ROUTES.INTAKE_ADD}
                      exact
                      component={IntakesAddPage}
                    />
                    <Route
                      path={ROUTES.INTAKE_EDIT}
                      exact
                      component={IntakeEditPage}
                    />
                    <Route
                      path={ROUTES.INTAKE_VIEW}
                      component={IntakeViewPage}
                    />
                    <Route
                      path={ROUTES.STUDENTS}
                      exact
                      component={StudentsPage}
                    />
                    <Route
                      path={ROUTES.STUDENTS_SEARCH}
                      exact
                      component={StudentsSearchPage}
                    />
                    <Route
                      path={ROUTES.STUDENTS_FILTER}
                      exact
                      component={StudentsFilterPage}
                    />
                    <Route
                      path={ROUTES.STUDENT_ADD}
                      exact
                      component={StudentsAddPage}
                    />
                    <Route
                      path={ROUTES.STUDENT_ADD_CSV}
                      exact
                      component={StudentsAddCsvPage}
                    />
                    <Route
                      path={ROUTES.STUDENT_EDIT}
                      exact
                      component={StudentsEditPage}
                    />
                    <Route
                      path={ROUTES.STUDENT_VIEW}
                      component={StudentsViewPage}
                    />
                    <Route
                      path={ROUTES.TEACHERS}
                      exact
                      component={TeachersPage}
                    />
                    <Route
                      path={ROUTES.TEACHERS_ADD}
                      exact
                      component={TeachersAddPage}
                    />
                    <Route
                      path={ROUTES.TEACHERS_VIEW}
                      exact
                      component={TeachersViewPage}
                    />

                    <Route path={ROUTES.TOOLS} exact component={ToolsPage} />
                    <Route
                      path={ROUTES.TOOL_VIEW}
                      exact
                      component={ToolsPage}
                    />
                    <Route path={ROUTES.HOME} component={HomePage} />
                  </Switch>
                </Page>
              </Box>
            </ContentWrap>
          </Box>
        </Grommet>
      </Router>
    )
  }
}

export default withAuthentication(App)

const Page = styled.div`
  color: ${theme.global.colors.black};
`

const ContentWrap = styled.div`
  max-width: 1400px;
  width: 100%;
`
