import app from 'firebase/app'
//import student_app from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

class Firebase {
  constructor() {
    app.initializeApp(config)

    console.log('🔥', config)

    //student_app.initializeApp(config, 'student');
    //this.student_auth = student_app.auth();

    this.auth = app.auth()
    this.functions = app.functions()

    if (process.env.NODE_ENV === 'development') {
      this.functions.useFunctionsEmulator('http://localhost:5000')
    }

    // store a static reference (for Timestamps)
    this.firestore = app.firestore

    this.db = app.firestore()
  }

  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.student_auth.createUserWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.userByEmail(authUser.email)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.docs[0].data()
            const isAdmin = dbUser.roles.admin

            if (isAdmin) {
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                intake: {},
                ...dbUser,
              }

              next(authUser)
            } else {
              fallback()
            }
          })
          .catch((error) => {
            console.log('onAuthStateChanged ERROR', error)
          })
      } else {
        fallback()
      }
    })

  // *** Users API ***

  userByEmail = (email) =>
    this.db.collection('users').where('email', '==', email).limit(1)

  // *** Intakes API ***

  intakes = () => this.db.collection('intakes').get()

  filteredIntakes = (campus, startDateTs, courseType) => {
    console.log('FILTER:', campus, startDateTs, courseType)

    // base
    let query = this.db.collection('intakes')

    if (campus != 'All') {
      query = query.where(`campus`, '==', campus)
    }

    if (courseType != 'All') {
      query = query.where(`courseType`, '==', courseType)
    }

    if (startDateTs != 'All') {
      query = query.where(`startDate`, '>', startDateTs)
    }

    return query.get()

    // return this.db
    //   .collection('intakes')
    //   .where(`campus`, '==', campus)
    //   .where(`courseType`, '==', courseType)
    //   .where(`startDate`, '>', startDateTs)
    //   .get()
  }

  intakesRecent = (limit) =>
    this.db.collection('intakes').orderBy(`endDate`, `desc`).limit(limit).get()

  intake = (uid) => this.db.collection('intakes').doc(uid).get()

  intakeDelete = (uid) => this.db.collection('intakes').doc(uid).delete()

  intakeCreate = (data) => this.db.collection('intakes').add(data)

  intakeUpdate = (uid, data) =>
    this.db.collection('intakes').doc(uid).update(data)

  intakesByCode = (code) =>
    this.db.collection('intakes').where('code', '==', code).get()

  // *** Students API ***

  newStudent = (data) => this.db.collection('users').add(data)

  studentUpdate = (uid, data) =>
    this.db.collection('users').doc(uid).update(data)

  student = (uid) => this.db.collection('users').doc(uid).get()

  studentDelete = (uid) => this.db.collection('users').doc(uid).delete()

  studentTmp = () => this.db.collection('users').doc()

  //studentByEmail = email => this.db.collection('students').where("email", "==", email)

  students = () =>
    this.db.collection('users').where('roles.student', '==', true)

  studentsRecent = (limit) =>
    this.db
      .collection('users')
      .where('roles.student', '==', true)
      .orderBy(`createdAt`, `desc`)
      .limit(limit)
      .get()

  teacher = (uid) => this.db.collection('users').doc(uid).get()

  teachersRecent = (limit) =>
    this.db.collection('users').where('roles.teacher', '==', true).get()

  // *** Invites API ***

  invite = (uid) => this.db.collection('invites').doc(uid).get()
}

export default Firebase
