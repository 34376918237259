import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import _ from 'lodash'
import queryString from 'query-string'
import { withAuthorization } from '../Session'
import { filterEndDateEarlierThan } from '../../util/helpers'
import Header from './Header'
import ListItem from './ListItem'
import ListFilter from '../Intakes/ListFilter'
import Loader from '../Global/Loader'

const CURR_DATE = new Date()
const CURR_YEAR = CURR_DATE.getFullYear()

const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  fetchErrors: null,
  studentsTotal: 0,
  studentsFetched: 0,
}

const INITIAL_VALUES = {
  campus: 'New York',
  year: '2019',
  courseType: 'Full Time',
}

const Filter = ({ history, location, firebase }) => {
  const [status, setStatus] = useState(INITIAL_STATE)
  const [values, setValues] = useState(null)
  const [results, setResults] = useState([])

  const { fetching, fetched, studentsTotal, studentsFetched } = status

  useEffect(() => {
    let isCancelled = false

    setStatus(INITIAL_STATE)
    setResults([])

    let values = INITIAL_VALUES
    if (location && location.search) {
      const parsed = queryString.parse(location.search)
      const { campus, year, courseType } = parsed
      values = {
        campus: campus || INITIAL_VALUES.campus,
        year: year || INITIAL_VALUES.year,
        courseType: courseType || INITIAL_VALUES.courseType,
      }
    }
    setValues(values)

    const fetchFiltered = async (values) => {
      setStatus((prevState) => ({
        ...prevState,
        fetching: true,
        studentsTotal: 0,
      }))

      // Get intakes based on filter, then get all the students :)
      // 1. Intakes
      const { campus, year, courseType } = values

      const startDate =
        year === '1997-2005'
          ? new Date(`${1997 - 1}-12-31`)
          : new Date(`${parseInt(year) - 1}-12-31`)
      const startTs = firebase.firestore.Timestamp.fromDate(startDate)

      const endMonths = courseType === 'Full Time' ? `03-01` : `09-01`
      const endYear =
        year === 'All' ? CURR_YEAR + 1 : year === '1997-2005' ? '2005' : year
      const endDate = new Date(`${parseInt(endYear) + 1}-${endMonths}`)

      console.log(endYear, 'SDfsdfsdf')

      const res = await firebase
        .filteredIntakes(campus, startTs, courseType)
        .catch((err) => {
          console.log('✘ Error:', err)
        })

      if (!isCancelled) {
        let studentIds = []
        if (res.docs.length > 0) {
          const filteredIntakes = filterEndDateEarlierThan(res, endDate)

          // 2. Students
          if (filteredIntakes.length > 0) {
            _.forEach(filteredIntakes, (doc) => {
              const { students } = doc.data()
              if (students) {
                const ids = _.keys(students)
                studentIds = studentIds.concat(ids)
              }
            })
          }
        }
        setStatus((prevState) => ({
          ...prevState,
          fetched: true,
          fetching: false,
          studentsTotal: studentIds.length,
        }))
        _.forEach(studentIds, (id) => {
          fetchStudent(id)
        })
      }
    }

    const fetchStudent = async (id) => {
      const studentDoc = await firebase.student(id)
      if (!isCancelled) {
        setStatus((prevState) => ({
          ...prevState,
          studentsFetched: prevState.studentsFetched + 1,
        }))
        if (studentDoc.data()) {
          setResults((prevState) => prevState.concat(studentDoc))
        }
      }
    }

    fetchFiltered(values)

    return () => {
      isCancelled = true
    }
  }, [firebase, location])

  let body
  if (fetching && !fetched) {
    body = <Loader />
  } else if (fetched) {
    if (studentsTotal > 0 && studentsFetched < studentsTotal) {
      body = <Loader />
    } else if (
      studentsTotal > 0 &&
      studentsFetched === studentsTotal &&
      results.length > 0
    ) {
      body = results.map((student, i) => (
        <ListItem key={i} doc={student} index={i} firebase={firebase} />
      ))
    } else if (studentsTotal === 0 || studentsFetched === studentsTotal) {
      body = (
        <Box
          border="bottom"
          justify="between"
          direction="row"
          pad={{
            vertical: 'medium',
          }}
        >
          No students found.
        </Box>
      )
    }
  }

  return (
    <Box>
      <Header slug="/students/filter" />
      <ListFilter
        history={history}
        initialValues={values}
        defaultValues={INITIAL_VALUES}
      />
      {body}
    </Box>
  )
}

const condition = (authUser) => !!authUser
export default withAuthorization(condition)(Filter)
